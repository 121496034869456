import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { database } from '../../base';
import { ref, onValue, set } from 'firebase/database';
import '../../styles/css/AdminMain.css';

class SetUpWebHooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            webHooksVariables: {},
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const webHooksVariablesRef = ref(database, 'webHooksVariables');
        onValue(webHooksVariablesRef, (snapshot) => {
            const webHooksVariables = snapshot.val() || {};
            this.setState({
                webHooksVariables,
                loading: false
            });
        }, (error) => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    handleChange(evt) {
        const webHooksVariablesCopy = { ...this.state.webHooksVariables };
        const target = evt.target;
        const name = target.name;
        webHooksVariablesCopy[name] = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ webHooksVariables: webHooksVariablesCopy });
        set(ref(database, `webHooksVariables/${name}`), webHooksVariablesCopy[name]);
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    render() {
        const webHooksVariables = this.state.webHooksVariables || {};
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel" style={{ color: "black" }}>
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Web Hooks</p>
                    <p className="admin-subheader-text">Manage your web hooks here that will send messages to links you specify when certain actions are taken</p>
                    <ol>
                        <li>
                            <div className="mb-3">
                                <label htmlFor="userCreationWebHookLink" className="form-label">New Users Web Hook</label>
                                <div className="form-text">This web hook fires every time a new account is created, please enter a URL that you would like to have notified of user creation</div>
                                <input id="userCreationWebHookLink" name="userCreationWebHookLink" type="url" className="form-control" value={webHooksVariables.userCreationWebHookLink || ''} onChange={this.handleChange} />
                            </div>
                        </li>
                        <li>
                            <div className="mb-3">
                                <label htmlFor="prizeWonWebHookLink" className="form-label">Prizes Won Web Hook</label>
                                <div className="form-text">This web hook fires every time a new prize is won, please enter a URL that you would like to have notified of user creation</div>
                                <input id="prizeWonWebHookLink" name="prizeWonWebHookLink" type="url" className="form-control" value={webHooksVariables.prizeWonWebHookLink || ''} onChange={this.handleChange} />
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        );
    }
}

export default SetUpWebHooks;
