import { initializeApp } from 'firebase/app';
import { getDatabase, ref, child, set, orderByChild, startAt, endAt, query, get, limitToFirst, equalTo } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_ID
};

const app = initializeApp(config);
const database = getDatabase(app);
const auth = getAuth(app);

const once = async (path) => {
  const dbRef = ref(database, path);
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    console.log('No data available');
    return null;
  }
};


const appDatabasePrimaryFunctions = {
  ref: (path) => ref(database, path),
  query: (ref, ...queryConstraints) => query(ref, ...queryConstraints),
  orderByChild,
  startAt,
  endAt,
  get,
  limitToFirst,
  equalTo
};

export { app, database, auth, appDatabasePrimaryFunctions };

