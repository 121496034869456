import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { database } from '../../base';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import { isObjectEmpty, Toast, ErrorMessage } from '../utils/HelpfulFunction';
import { ref, onValue, set } from 'firebase/database';

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesAndRegsText: RichTextEditor.createEmptyValue(),
            howToPlayText: RichTextEditor.createEmptyValue(),
            rulesPopUpText: RichTextEditor.createEmptyValue(),
            rulesShowInAppPopUpText: RichTextEditor.createEmptyValue(),
            supportText: RichTextEditor.createEmptyValue(),
            rulesPopUpHeader: "",
            rulesShowInAppPopUpHeader: "",
            rulesInAppButtonText: "",
            howToPlayLink: "",
            tenantRules: null,
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const tenantRulesRef = ref(database, 'tenantRules');
        onValue(tenantRulesRef, (snapshot) => {
            const data = snapshot.val() || {};
            this.setState({
                rulesAndRegsText: data.rulesAndRegsText ? RichTextEditor.createValueFromString(data.rulesAndRegsText, 'html') : RichTextEditor.createEmptyValue(),
                howToPlayText: data.howToPlayText ? RichTextEditor.createValueFromString(data.howToPlayText, 'html') : RichTextEditor.createEmptyValue(),
                rulesPopUpText: data.rulesPopUpText ? RichTextEditor.createValueFromString(data.rulesPopUpText, 'html') : RichTextEditor.createEmptyValue(),
                rulesShowInAppPopUpText: data.rulesShowInAppPopUpText ? RichTextEditor.createValueFromString(data.rulesShowInAppPopUpText, 'html') : RichTextEditor.createEmptyValue(),
                supportText: data.supportText ? RichTextEditor.createValueFromString(data.supportText, 'html') : RichTextEditor.createEmptyValue(),
                rulesPopUpHeader: data.rulesPopUpHeader || "",
                rulesShowInAppPopUpHeader: data.rulesShowInAppPopUpHeader || "",
                rulesInAppButtonText: data.rulesInAppButtonText || "",
                howToPlayLink: data.howToPlayLink || "",
                rulesShowInApp: data.rulesShowInApp || false,
                advanced: !!data.rulesShowInApp || !!data.howToPlayText || !!data.rulesPopUpText,
                supportHeader: data.supportHeader || "",
                loading: false
            });
        });
    }

    handleChange(evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    handleRichTextChange = (name, text) => {
        this.setState({ [name]: text });
    }

    handleSubmit(event) {
        event.preventDefault();
        let rulesAndRegsText = this.state.rulesAndRegsText.toString('html');
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let supportHeader = this.state.supportHeader || "";
        let supportText = this.state.supportText.toString('html');
        let howToPlayLink = this.state.howToPlayLink;
        let rulesPopUpText = this.state.rulesPopUpText.toString('html');
        let howToPlayText = this.state.howToPlayText.toString('html');
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText.toString('html');
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        if(rulesInAppButtonText && rulesInAppButtonText.length > 18){
            ErrorMessage.fire({
                title: "Input Error",
                text: "Button Text Cannot Be Longer Then 18 Characters",
            });
            return;
        }
        let rulesShowInApp = this.state.rulesShowInApp || false;
        if(howToPlayText === "<p><br></p>"){
            howToPlayText = "";
        }
        if(supportText === "<p><br></p>"){
            supportText = "";
        }
        if(rulesPopUpText === "<p><br></p>"){
            rulesPopUpText = "";
        }
        if(rulesShowInAppPopUpText === "<p><br></p>"){
            rulesShowInAppPopUpText = "";
        }
        if(rulesAndRegsText === "<p><br></p>"){
            rulesAndRegsText = "";
        }
        let updateRulesObject = {
            "rulesAndRegsText": rulesAndRegsText, "howToPlayLink": howToPlayLink,
            "howToPlayText": howToPlayText, "rulesPopUpText": rulesPopUpText, "rulesPopUpHeader":rulesPopUpHeader,
            "rulesShowInAppPopUpText": rulesShowInAppPopUpText, "rulesShowInAppPopUpHeader": rulesShowInAppPopUpHeader,
            "rulesInAppButtonText": rulesInAppButtonText, "rulesShowInApp": rulesShowInApp, "supportText": supportText,
            "supportHeader": supportHeader
        }

        console.log(updateRulesObject)

        this.setState({ loading: true });

        set(ref(database, 'tenantRules'), updateRulesObject)
            .then(() => {
                this.setState({ loading: false });
                Toast.fire({
                    icon: 'success',
                    title: 'Rules and Regs Updated!'
                });
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
                this.setState({ loading: false });
                ErrorMessage.fire({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                });
            });
    }

    render() {
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };

        let {
            rulesAndRegsText,
            rulesPopUpHeader,
            rulesPopUpText,
            rulesInAppButtonText,
            rulesShowInAppPopUpHeader,
            rulesShowInAppPopUpText,
            howToPlayLink,
            howToPlayText,
            supportHeader,
            supportText,
            loading,
            advanced,
            rulesShowInApp
        } = this.state;

        if(isObjectEmpty(rulesPopUpText)){
            rulesPopUpText = ""
        }
        if(isObjectEmpty(rulesAndRegsText)){
            rulesAndRegsText = ""
        }
        if(isObjectEmpty(howToPlayLink)){
            howToPlayLink = ""
        }
        if(isObjectEmpty(rulesPopUpHeader)){
            rulesPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpHeader)){
            rulesShowInAppPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = ""
        }
        if(isObjectEmpty(rulesInAppButtonText)){
            rulesInAppButtonText = ""
        }
        if(isObjectEmpty(supportHeader)){
            supportHeader = ""
        }

        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="container-out" style={{ width: '45%', float: 'left' }}>
                        <div className="admin-form-box">
                            <form onSubmit={this.handleSubmit} id="create-game-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: '20px' }}>
                                    <span className="fa fa-arrow-circle-o-up" /> Update
                                </button>
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>
                                        This text will appear AFTER the mandatory confirm rules and regs button on the login form
                                        <br />
                                        <strong>Example:</strong> Welcome to Spin To Win! To play, please confirm that you agree with the rules and regs
                                    </p>
                                    <RichTextEditor id="rulesAndRegsText" name="rulesAndRegsText" toolbarConfig={toolbarConfig} value={rulesAndRegsText} onChange={(text) => this.handleRichTextChange('rulesAndRegsText', text)} />
                                </div>
                                {!isMlbApp &&
                                    <div className="form-group">
                                        <label htmlFor="showAdvancedSettings">Advanced</label>
                                        <br />
                                        <input type="checkbox" checked={advanced} id="advanced" name="advanced" onChange={this.handleChange} />
                                    </div>
                                }
                                {advanced &&
                                    <>
                                        {!isMlbApp &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="howToPlayLink">How To Play Link</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>This is where you can teach fans how to play</p>
                                                    <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleChange} placeholder="https://ourgameplay.com" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="howToPlayText">How To Play Text (replaces link if filled)</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>This is where you can teach fans how to play</p>
                                                    <RichTextEditor id="howToPlayText" name="howToPlayText" toolbarConfig={toolbarConfig} value={howToPlayText} onChange={(text) => this.handleRichTextChange("howToPlayText", text)} />
                                                </div>
                                            </>
                                        }
                                        {!isMlbApp &&
                                            <div className="form-group">
                                                <label htmlFor="rulesShowInApp">Show Rules And Regs After Sign Up Screen</label>
                                                <br />
                                                <input type="checkbox" checked={rulesShowInApp} id="rulesShowInApp" name="rulesShowInApp" onChange={this.handleChange} />
                                            </div>
                                        }
                                        {rulesShowInApp &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="rulesInAppButtonText">Button Text</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The text of the button that links to Rules & Regs</p>
                                                    <input id="rulesInAppButtonText" name="rulesInAppButtonText" type="text" className="form-control" value={rulesInAppButtonText} onChange={this.handleChange} placeholder="Rules & Regs" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="rulesShowInAppPopUpHeader">Pop Up After Sign Up (Optional)</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The header of the in-app pop up</p>
                                                    <input id="rulesShowInAppPopUpHeader" name="rulesShowInAppPopUpHeader" type="text" className="form-control" value={rulesShowInAppPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="rulesShowInAppPopUpText">Rules & Regs Pop Up Text</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The body text of the pop up</p>
                                                    <RichTextEditor id="rulesShowInAppPopUpText" name="rulesShowInAppPopUpText" toolbarConfig={toolbarConfig} value={rulesShowInAppPopUpText} onChange={(text) => this.handleRichTextChange("rulesShowInAppPopUpText", text)} />
                                                </div>
                                            </>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="rulesPopUpHeader">Rules & Regs Pop Up Header (Optional)</label>
                                            <p className="text-muted2" style={{ fontSize: '10px' }}>The header of the in-app pop up containing rules</p>
                                            <input id="rulesPopUpHeader" name="rulesPopUpHeader" type="text" className="form-control" value={rulesPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="rulesPopUpText">Rules & Regs Pop Up Text</label>
                                            <p className="text-muted2" style={{ fontSize: '10px' }}>The text of the in-app pop up. When this is filled in it will replace the rules and regs link and the text here will show up in a pop up.</p>
                                            <RichTextEditor id="rulesPopUpText" name="rulesPopUpText" toolbarConfig={toolbarConfig} value={rulesPopUpText} onChange={(text) => this.handleRichTextChange("rulesPopUpText", text)} />
                                        </div>
                                        {isMlbApp &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="supportHeader">Support Header</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The header of the in-app pop up containing support information</p>
                                                    <input id="supportHeader" name="supportHeader" type="text" className="form-control" value={supportHeader} onChange={this.handleChange} placeholder="NEED SUPPORT?" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="supportText">Support Body</label>
                                                    <p className="text-muted2" style={{ fontSize: '10px' }}>The text of the support in-app pop up.</p>
                                                    <RichTextEditor id="supportText" name="supportText" toolbarConfig={toolbarConfig} placeholder="Email customerservice@website.mlb.com with your issue and we'll be in touch!" value={supportText} onChange={(text) => this.handleRichTextChange("supportText", text)} />
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                    <div className="container-out" style={{ width: '45%', float: 'right', marginRight: '20px' }}>
                        <div className="admin-form-box" style={{ border: '1px solid black', backgroundColor: 'white' }}>
                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Why do I need rules & regulations?</p>
                            <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws. It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpRulesAndRegs;
