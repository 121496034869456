import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {ErrorMessage, Toast} from '../utils/HelpfulFunction';

class SetUpStatFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    async updateStatFeedRoster(){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/updateStatFeedRoster`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/updateStatFeedRoster`
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            }
        })
        const responseJson = await response.json();
        this.setState({loading: false});
        if(responseJson.results){
            await Toast.fire({
                title: 'Roster Updated!'
            })
        } else {
            await ErrorMessage.fire({
                title: "Oh No!",
                text: 'Something went wrong... Try again!'
            })
        }

    }

    render() {
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "sqwadbingodev";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0}}>Stat Feed Management</p>
                <p className="admin-subheader-text">Manage your stat feed here</p>
                <div style={{marginLeft: 20, color:"black"}}>
                    <button onClick={()=>this.updateStatFeedRoster()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginLeft: 20}}>
                        <span className="fa fa-refresh"/> Regenerate Roster
                    </button>
                </div>
            </div>
         </div>
        );
    }
}

export default SetUpStatFeed
