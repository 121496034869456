import React, { Component } from 'react';
import { database } from '../../base'; // Adjust the import to point to your firebase configuration file
import { ref, onValue } from 'firebase/database';
import '../../styles/css/Scoreboard.css';

class Scoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentGame: {},
            tenantVariables: {}
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        document.title = "Scoreboard";

        const tenantVariablesRef = ref(database, 'tenantVariables');
        onValue(tenantVariablesRef, (snapshot) => {
            const variables = snapshot.val();
            this.setState({
                tenantVariables: variables,
                loading: false
            });
        });

        const bingoSquaresScoreboardRef = ref(database, 'currentGame/bingoSquaresScoreboard');
        onValue(bingoSquaresScoreboardRef, (snapshot) => {
            const bingoSquaresScoreboard = snapshot.val() ? Object.values(snapshot.val()) : [];
            this.setState({
                bingoSquaresScoreboard
            });
        });

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    componentWillUnmount() {
        // Firebase listeners do not need to be manually removed as they are on a component basis.
        // Just make sure to handle the unmounting of components gracefully.
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const filledInIcon = tenantVariables.filledInIcon || "";
        const backgroundImage = tenantVariables.backgroundScoreboardImage;
        const sideScoreboardImage = tenantVariables.sideScoreboardImage;
        const bingoSquares = this.state.bingoSquaresScoreboard || [];
        if (this.state.loading) {
            return (
                <div className="loading-screen"/>
            )
        }
        let sizeOfBingoSquares = 100;
        let widthOfEachSquare = "";

        let shortestScreenHeight = this.state.height >= this.state.width ? this.state.width : this.state.height;
        if (bingoSquares && bingoSquares.length > 0) {
            const numberOfSquaresInRow = Math.sqrt(bingoSquares.length);
            sizeOfBingoSquares = (shortestScreenHeight * 0.9) / numberOfSquaresInRow;
            for (let i = 0; i < numberOfSquaresInRow; i++) {
                if (widthOfEachSquare) {
                    widthOfEachSquare = widthOfEachSquare + " " + sizeOfBingoSquares.toString() + "px"
                } else {
                    widthOfEachSquare = sizeOfBingoSquares.toString() + "px";
                }
            }
        }
        return (
            <div className="grid-wrapper" style={{
                backgroundImage: "url(" + backgroundImage + ")",
                display: 'flex',
                justifyContent: "center",
                alignItems: "center"
            }}>
                {/*{this.state.startConfetti &&*/}
                {/*    <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>*/}
                {/*}*/}
                <div style={{maxWidth: "100%", maxHeight: "100%"}}>
                    <img src={sideScoreboardImage} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>
                </div>
                <div style={{
                    display: 'grid',
                    gridAutoFlow: "column",
                    gridTemplateColumns: widthOfEachSquare,
                    gridTemplateRows: widthOfEachSquare
                }}>
                    {
                        bingoSquares.map(function (item, index) {
                            if (!item.id) {
                                return;
                            }
                            let originalItem = item;
                            return (
                                <div key={index} className="flip-container" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: "1%",
                                    width: "100%",
                                    height: "100%",
                                    alignItems: "center"
                                }}>
                                    <div style={{width: "100%", height: "100%"}}>
                                        {originalItem.completed ?
                                            <div className="background-image-styles" style={{backgroundImage: "url(" + originalItem.image + ")"}}>
                                                <img width={"100%"} height={"100%"} src={originalItem.checkedImage || filledInIcon} alt=""/>
                                            </div>
                                            :
                                            <img width={"100%"} height={"100%"} src={originalItem.image} alt=""/>
                                        }
                                    </div>
                                </div>
                            )
                        }, this)
                    }
                </div>
            </div>
        );
    }
}

export default Scoreboard;
