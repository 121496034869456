import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { getDatabase, ref, query, orderByChild, startAt, endAt, limitToLast, get, child } from "firebase/database";
import {database} from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import { cleanCSVText, findFirstAndLastName, convertTimeStampToHumanReadable } from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';

class AdminHome extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate() - 30);
        todaysDateObject.setHours(0, 0, 0, 0); // Sets the time to start of the day (midnight)
        const endDateObject = new Date();
        endDateObject.setHours(23, 59, 59, 999); // Sets the time to end of the day (11:59 pm)
        let scoreboardLink = "";
        const splitHref = window.location.href.split("/");
        splitHref.pop();
        scoreboardLink = splitHref.join("/") + "/scoreboard";
        this.state = {
            games: 0,
            users: 0,
            itemsPerPage: 10,
            itemsToShow: 10,
            uniqueGamesUsers: 0,
            loading: false,
            players: 0,
            gameUsers: 0,
            prizesWon: 0,
            lastGame: {},
            uniqueGameUsers: false,
            gamesToShowList: [],
            searchStart: todaysDateObject,
            searchEnd: endDateObject,
            scoreboardLink: scoreboardLink
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    async componentDidMount() {
        await this.getGames(this.state.searchStart.getTime());
        await this.getUsers(this.state.searchStart.getTime());
    }

    async getGames(start = 0, end) {
        const gamesRef = ref(database, 'gamesList');
        const currentGameRef = ref(database, 'currentGame');

        let currentGameObject = (await get(currentGameRef)).val() || null;

        if (currentGameObject && currentGameObject.timeStamp <= start) {
            currentGameObject = null;
        }

        if (end && currentGameObject && currentGameObject.timeStamp > end) {
            currentGameObject = null;
        }

        const currentGame = currentGameObject;

        let currentGamePrizes = 0;
        let currentGameUsers = 0;
        let gamesCount = 0;

        if (currentGame) {
            currentGamePrizes = await get(ref(database, 'emailsSent')).then(snapshot => snapshot.size);
            currentGameUsers = await get(ref(database, "userAnswers")).then(snapshot => snapshot.size);
            gamesCount++;
            currentGame.prizesWon = currentGamePrizes;
            currentGame.users = currentGameUsers;
            currentGame.currentGame = true;
        }

        const gamesQuery = end ? query(gamesRef, orderByChild('timeStamp'), startAt(start), endAt(end)) : query(gamesRef, orderByChild('timeStamp'), startAt(start));
        const gamesSnapshot = await get(gamesQuery);

        let gamesArray = [];
        let prizesCount = currentGamePrizes;
        let gameUsersCount = currentGameUsers;

        gamesSnapshot.forEach(data => {
            prizesCount += data.val().prizesWon || 0;
            gameUsersCount += data.val().users || 0;
            gamesArray.unshift(data.val());
        });

        if (currentGame) {
            gamesArray.unshift(currentGame);
        }

        this.setState({
            loading: false,
            games: gamesCount + gamesSnapshot.size,
            prizesWon: prizesCount,
            gameUsers: gameUsersCount,
            gamesToShowList: gamesArray
        });
    }

    async getUsers(start = 0, end, returnUsers = false) {
        const usersRef = ref(database, 'users');
        let usersQuery = query(usersRef, orderByChild('signUpTime'), startAt(start));
        if (end) {
            usersQuery = query(usersRef, orderByChild('signUpTime'), startAt(start), endAt(end));
        }
        const usersSnapshot = await get(usersQuery);

        if (returnUsers) {
            if (usersSnapshot.exists()) {
                return usersSnapshot.val();
            } else {
                return {};
            }
        } else {
            this.setState({
                loading: false,
                users: usersSnapshot.size
            });
        }
    }

    async getUserData() {
        this.setState({
            loading: true
        });
        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if (typeof searchStart === "object") {
            searchStart = searchStart.getTime();
        }
        if (searchEnd && typeof searchEnd === "object") {
            searchEnd = searchEnd.getTime();
        }
        let csv = process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split("T")[0];
        csv += ",Bingo\n,\n"
        csv += "Date Data Downloaded," + date_got + "\n";
        csv += "From," + (searchStart ? convertTimeStampToHumanReadable(searchStart) : "") + "\n";
        csv += "To," + (searchEnd ? convertTimeStampToHumanReadable(searchEnd) : "") + "\n,\n";
        csv += 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Country,Signed Up\n';
        const vm = this;
        let timeFrameUsers = await this.getUsers(searchStart, searchEnd, true);
        for (let timeFrameUserIndex in timeFrameUsers) {
            let user = timeFrameUsers[timeFrameUserIndex];
            let { firstName, secondName } = findFirstAndLastName(user.name);
            csv += (user.email || user.uid || "") + ",";
            csv += (cleanCSVText(firstName) || "") + ",";
            csv += (cleanCSVText(secondName) || "") + ",";
            csv += (user.phoneNumber || "") + ",";
            csv += (cleanCSVText(user.zipCode) || "") + ",";
            csv += (cleanCSVText(user.address) || "") + ",";
            csv += (user.birthday || "") + ",";
            csv += (user.optIn || "") + ",";
            csv += (user.country || "") + ",";
            csv += (user.signUpTime ? convertTimeStampToHumanReadable(user.signUpTime) : "")
            csv += "\n";
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = (searchStart ? "_" + convertTimeStampToHumanReadable(searchStart) : "") + (searchEnd ? "_" + convertTimeStampToHumanReadable(searchEnd) : "");
        cleanString = "bingo_accounts_made" + cleanString.replace(/[|&;$%@"<>()+,]/g, "").toLowerCase() + ".csv";
        hiddenElement.download = cleanString;
        hiddenElement.click();
        vm.setState({
            loading: false
        });
    }

    async downloadIntenseGameData(game) {
        this.setState({
            loading: true
        });
        let csv = process.env.REACT_APP_FAN_LINK + ',' + 'Bingo\n""\n';
        let date_downloaded = new Date((new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split("T")[0];
        const gameAnswers = await get(child(ref(database), `userAnswersHistory/${game.id}`)).then(snapshot => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return [];
            }
        });
        let totalPrizes = 0;
        for (const userIndex in gameAnswers) {
            const userInfo = gameAnswers[userIndex];
            if (userInfo.rewardSent) {
                totalPrizes++;
            }
        }
        csv += 'Date Data Downloaded,' + date_downloaded + '\n';
        csv += 'Game Name,' + game.gameName + '\n';
        csv += 'Start Time,' + (game.scheduleInfo ? convertTimeStampToHumanReadable(game.scheduleInfo.performAt) : "") + '\n';
        csv += 'End Time,' + (game.scheduleInfo ? convertTimeStampToHumanReadable(game.scheduleInfo.endAt) : "") + '\n';
        csv += 'Prizes Won,' + totalPrizes.toString() + '\n""\n';
        csv += 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Country,Bingo?,Reward,Code,Redeemed\n';

        const usersData = await get(ref(getDatabase(), 'users')).then(usersSnapshot => {
            return usersSnapshot.val();
        });

        for (const userIndex in gameAnswers) {
            const userInfo = gameAnswers[userIndex];
            const correct = userInfo.bingo === "Yes" ? "YES" : "NO";
            const moreUserData = usersData[userInfo.uid];
            let { firstName, secondName } = findFirstAndLastName(moreUserData.name);
            csv += (userInfo.email || userInfo.uid || "")
                + ',' + (cleanCSVText(firstName) || "")
                + ',' + (cleanCSVText(secondName) || "")
                + ',' + (moreUserData.phoneNumber || "")
                + ',' + (cleanCSVText(moreUserData.zipCode) || "")
                + "," + (cleanCSVText(moreUserData.address) || "")
                + ',' + (moreUserData.birthday || "")
                + ',' + (moreUserData.optIn || "")
                + ',' + (moreUserData.country || "")
                + ',' + (correct || "")
                + ',' + (cleanCSVText(userInfo.rewardSent) || "")
                + ',' + (cleanCSVText(userInfo.code) || "")
                + ',' + (userInfo.isRedeemed ? "true" : "false")
                + '\n';
        }
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = game.gameName.replace(/[|&;$%@"<>()+,. ]/g, "").toLowerCase();
        hiddenElement.download = "bingo_game_data_" + cleanString + ".csv";
        hiddenElement.click();
        this.setState({
            loading: false
        });
    }

    async searchData() {
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if (searchStart) {
            searchStart = searchStart.getTime();
        }
        if (searchEnd) {
            searchEnd = searchEnd.getTime();
        }
        this.setState({
            loading: true,
            itemsPerPage: this.state.itemsPerPage
        });
        await this.getGames(searchStart, searchEnd);
        await this.getUsers(searchStart, searchEnd);
    }

    onNextPage() {
        const vm = this;
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if (searchStart) {
            searchStart = searchStart.getTime();
        }
        if (searchEnd) {
            searchEnd = searchEnd.getTime();
        }
        let queryString = query(ref(database, 'gamesList'), orderByChild('timeStamp'), limitToLast(newAmountToShow), startAt(searchStart));
        if (searchEnd) {
            queryString = query(ref(database, 'gamesList'), orderByChild('timeStamp'), limitToLast(newAmountToShow), startAt(searchStart), endAt(searchEnd));
        }
        get(queryString).then(snapshot => {
            let gamesArray = [];
            snapshot.forEach(data => {
                gamesArray.unshift(data.val());
            });
            vm.setState({
                itemsToShow: newAmountToShow,
                gamesToShowList: gamesArray
            });
        });
    }

    render() {
        const totalUsers = this.state.users || 0;
        const gameUsers = this.state.gameUsers || 0;
        const prizesWon = this.state.prizesWon || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-md-11">
                            <p className="admin-header-text" style={{ marginTop: '10px' }}>
                                {!isMlbApp &&
                                    <>
                                        Game link: <span style={{ fontSize: '20px' }}>{fan_side_link}</span>
                                    </>
                                }
                                <br />
                                Scoreboard link: <span style={{ fontSize: '20px' }}>{this.state.scoreboardLink}</span>
                            </p>
                        </div>
                        <div className="col-md-1">
                            <p style={{ color: "black" }}>
                                v{process.env.REACT_APP_VERSION}
                            </p>
                        </div>
                    </div>
                    <form className="form-horizontal" style={{ marginLeft: 20, marginBottom: 10 }}>
                        <div className="row g-3 align-items-center">
                            <div className="col-auto">
                                <DatePicker
                                    isClearable
                                    selected={this.state.searchStart}
                                    onChange={date => {
                                        if (date) date.setHours(0, 0, 0, 0);
                                        this.setState({ searchStart: date });
                                    }}
                                />
                            </div>

                            <div className="col-auto">
                                <DatePicker
                                    isClearable
                                    selected={this.state.searchEnd}
                                    onChange={date => {
                                        if (date) date.setHours(23, 59, 59, 999);
                                        this.setState({ searchEnd: date });
                                    }}
                                />
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-primary btn-admin" type="button" onClick={() => this.searchData()}>Search</button>
                            </div>
                        </div>
                    </form>
                    <div className="admin-grid-container four-columns">
                        <div className="card card-styles text-xs-center" style={{ backgroundColor: "black" }}>
                            <div className="card-body">
                                <blockquote className="card-bodyquote" style={{ margin: 0 }}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p style={{ color: "white", fontSize: 50, margin: 0 }}>{totalUsers}</p>
                                            <span style={{ color: "#f8f8ff", fontSize: 20 }}>Accounts Created</span>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: 40, alignSelf: "center" }}>
                                            <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{ color: "white", cursor: "pointer" }} onClick={() => this.getUserData()} />
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div className="card card-styles text-xs-center" style={{ backgroundColor: "black" }}>
                            <div className="card-body">
                                <blockquote className="card-bodyquote" style={{ margin: 0 }}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p style={{ color: "white", fontSize: 50, margin: 0 }}>{gameUsers}</p>
                                            <span style={{ color: "#f8f8ff", fontSize: 20 }}>Game Users</span>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div className="card card-styles text-xs-center" style={{ backgroundColor: "black" }}>
                            <div className="card-body">
                                <blockquote className="card-bodyquote" style={{ margin: 0 }}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <p style={{ color: "white", fontSize: 50, margin: 0 }}>{prizesWon}</p>
                                            <span style={{ color: "#f8f8ff", fontSize: 20 }}>Prizes Won</span>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: 20 }}>
                        {this.state.gamesToShowList && this.state.gamesToShowList.length > 0 &&
                            <>
                                {
                                    this.state.gamesToShowList.map(function (item, index) {
                                        return (
                                            <div key={index} className="card card-styles text-xs-center"
                                                 style={{ marginBottom: 10 }}>
                                                <div className="card-body"
                                                     style={{ boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px" }}>
                                                    <div className="row" style={{ alignItems: 'center' }}>
                                                        <div className="col-md-2">
                                                            <span style={{ color: 'black', fontSize: 14 }}>{convertTimeStampToHumanReadable(item.timeStamp)}</span>
                                                        </div>
                                                        <div className="col-md-3" style={{ fontSize: 30 }}>
                                                            {item.gameName}
                                                        </div>
                                                        <div className="col-md-3" style={{ textAlign: 'right' }}>
                                                            <span style={{ fontSize: 25 }}>{item.users || 0}</span>
                                                            <span style={{ color: 'black', fontSize: 15, marginRight: 15 }}>Played</span>
                                                            <span style={{ fontSize: 25 }}>{item.prizesWon || 0}</span>
                                                            <span style={{ color: 'black', fontSize: 15 }}>Prizes Won</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row" style={{ fontSize: 20, justifyContent: 'center' }}>
                                                                {item.prizesWon !== null && !item.currentGame &&
                                                                    <button onClick={() => this.downloadIntenseGameData(item)} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                        <span className="fa fa-arrow-circle-down" /> Download Game Data
                                                                    </button>
                                                                }
                                                                {item.currentGame &&
                                                                    <button onClick={() => window.location.href = '/setupgame'} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                        Go To Current Game
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }, this)
                                }
                                {this.state.games > this.state.gamesToShowList.length &&
                                    <button className="btn btn-primary" onClick={() => this.onNextPage()}>
                                        More
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminHome;
