import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome';
import AdminLogin from './components/admin/AdminLogin';
import SetUpRewards from './components/admin/SetUpRewards';
import SetUpGame from './components/admin/SetUpGame';
import SetUpBrandingVariables from './components/admin/SetUpBrandingVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpEmail from './components/admin/SetUpEmail';
import SetUpBingoSquares from './components/admin/SetUpBingoSquares';
import LoginVariables from './components/admin/SetUpLoginVariables';
import Scoreboard from './components/scoreboard/Scoreboard';
import SetUpWebHooks from './components/admin/SetUpWebHooks';
import SetUpApi from './components/admin/SetUpApi';
import ApiDocumentation from './components/admin/ApiDocumentation';
import { app } from './base';
import SetUpStatFeed from './components/admin/SetUpStatFeed';

const auth = getAuth(app);

function PrivateRoute({ authenticated, children }) {
    return authenticated === true ? children : <Navigate to="/adminlogin" />;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true,
        };
    }

    setCurrentUser(user) {
        if (user) {
            this.setState({
                currentUser: user,
                authenticated: true,
            });
        } else {
            this.setState({
                currentUser: null,
                authenticated: false,
            });
        }
    }

    componentDidMount() {
        this.removeAuthListener = onAuthStateChanged(auth, (user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false,
                });
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        this.removeAuthListener();
    }

    render() {
        if (this.state.loading === true) {
            return <Loading loading={this.state.loading} />;
        }
        return (
            <BrowserRouter>
                <Routes>
                    <Route
                        path="adminlogin"
                        element={<AdminLogin setCurrentUser={this.setCurrentUser} user={this.state.currentUser} />}
                    />
                    <Route
                        path="/admin"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <AdminHome />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/scoreboard"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <Scoreboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setupbingosquares"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpBingoSquares currentUser={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setupgame"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpGame currentUser={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setuptickets"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpRewards />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setupteamvariables"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpBrandingVariables />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setuprulesandregs"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpRulesAndRegs />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setupticketemail"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpEmail />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/setuploginvariables"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <LoginVariables />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/webhooks"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpWebHooks />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/apimanagement"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpApi />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/apidocumentation"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <ApiDocumentation />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/statfeedlink"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <SetUpStatFeed currentUser={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to="/adminlogin" replace />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
