import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import {Modal} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, database} from '../../base';
import '../../styles/css/AdminMain.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import DatePicker from '../utils/DatePicker';
import InfiniteScroll from 'react-infinite-scroller';
import {
    cleanCSVText,
    convertTimeStampToHumanReadable,
    ErrorMessage,
    findFirstAndLastName,
    getSnapshotFromEndpoint,
    Toast,
    WarningMessage
} from '../utils/HelpfulFunction';
import {PulseLoader as LoadingSpinner} from "react-spinners";
import {ref, get, set, update, remove, onValue, off, push, orderByChild, equalTo, query} from 'firebase/database';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketList: [],
            itemsList: [],
            itemsAdded: [],
            rewardsAdded: [],
            codes: false,
            rewardToAdd: '',
            rewardAmount: 0,
            codesArray: '',
            qrCodes: false,
            emailsSent: {},
            activeSquareCodes: {},
            emailVariables: {},
            addedItems:[],
            currentGame: null,
            modal: false,
            users: [],
            gameName: "",
            loading: true,
            wizardLocation: 'first',
            endTime: new Date(),
            startTime: new Date(),
            itemsPerPage: 25,
            itemsToShow: 25,
            deleteAddedItems: false,
            userAnswers: [],
            randomOrder: false,
            randomScoreboardOrder: false,
            markLoading: false,
            loadingMessage: "Starting loading process",
            showPersonalPrizes: true,
            showIndividualRewards: true,
            responseSize: 100,
            page: 1,
            filterBingoSquares: "",
            showStats: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const vm = this;
        get(ref(database, 'tenantVariables')).then((snapshot) => {
            let tenantVariables = {};
            if (snapshot.exists() && snapshot.val()) {
                tenantVariables = snapshot.val();
            }
            let nameInEmail = false;
            if (tenantVariables.collectName && tenantVariables.nameInEmail) {
                nameInEmail = true;
            }
            vm.setState({
                sanitizeEmails: tenantVariables.sanitizeEmails,
                nameInEmail: nameInEmail,
                scratchingTurnedOff: tenantVariables.scratchingTurnedOff
            });
        });

        get(ref(database, 'emailVariables')).then((snapshot) => {
            let emailVariables = {};
            if (snapshot.exists() && snapshot.val()) {
                emailVariables = snapshot.val();
            }
            vm.setState({
                emailVariables: emailVariables
            });
        });

        onValue(ref(database, 'emailsSent'), (snapshot) => {
            vm.setState({
                userWinnersCount: snapshot.size
            });
        });

        onValue(ref(database, 'userAnswers'), (snapshot) => {
            let userAnswerWinnerCount = 0;
            snapshot.forEach((data) => {
                const dataAnswer = data.val();
                if (dataAnswer.won) {
                    userAnswerWinnerCount++;
                }
            });
            vm.setState({
                userAnswerCount: snapshot.size,
                userAnswerWinnerCount: userAnswerWinnerCount,
                loading: false
            });
        });

        onValue(ref(database, 'currentGame'), (snapshot) => {
            const currentGame = snapshot.val() || null;
            vm.setState({
                currentGame: currentGame,
                loading: false
            }, () => {
                if (currentGame && currentGame.id) {
                    vm.getRedemptions(currentGame.id);
                }
            });
        });

        get(ref(database, 'bingoCardsList')).then((snapshot) => {
            const itemsList = snapshot.val() ? Object.values(snapshot.val()) : [];
            vm.setState({
                itemsList: itemsList
            });
        });

        get(ref(database, 'rewardsList')).then((snapshot) => {
            const itemsList = snapshot.val() ? Object.values(snapshot.val()) : [];
            vm.setState({
                ticketList: itemsList
            });
        });
    }

    async getRedemptions(gameId) {
        if (!gameId) return;

        onValue(ref(database, 'redeemedPrize'), (snapshot) => {
            let countOfWinners = 0;
            snapshot.forEach((data) => {
                if (data.val().gameId === gameId && data.val().redeemed) {
                    countOfWinners++;
                }
            });
            this.setState({
                redemptions: countOfWinners
            });
        });
    }

    async organizeUserAnswers(numberOfResults=0, searchData=null, currentGame={}) {
        let playedUsers = [];
        let emailsSentQueryRef;
        let usersAnswersQueryRef;
        if(searchData){
            emailsSentQueryRef = getSnapshotFromEndpoint('emailsSent', appDatabasePrimaryFunctions, numberOfResults, searchData, 'name');
            usersAnswersQueryRef = getSnapshotFromEndpoint('userAnswers', appDatabasePrimaryFunctions, null, "", 'timeStamp');
        } else {
            emailsSentQueryRef = getSnapshotFromEndpoint('emailsSent', appDatabasePrimaryFunctions);
            if(numberOfResults){
                usersAnswersQueryRef = getSnapshotFromEndpoint('userAnswers', appDatabasePrimaryFunctions, numberOfResults, "", 'timeStamp');
            } else {
                usersAnswersQueryRef = getSnapshotFromEndpoint('userAnswers', appDatabasePrimaryFunctions, null, "", 'timeStamp');
            }
        }
        let gameId = null;
        if(currentGame && currentGame.id){
            gameId = currentGame.id;
        }
        const snapshots = await Promise.all([usersAnswersQueryRef, emailsSentQueryRef, getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions), getSnapshotFromEndpoint('redeemedPrize', appDatabasePrimaryFunctions, null, null, 'gameId', gameId)])
        let userAnswersRef = snapshots[0];
        let refToGoOver;
        let emailsSentRef = snapshots[1];
        let usersAnswerObject;
        const emailsSentObject = {};
        if(!searchData){
            emailsSentRef.forEach(function (data){
                const userEmailSent = data.val() || {};
                if(!emailsSentObject[userEmailSent.uid]){
                    emailsSentObject[userEmailSent.uid] = [];
                }
                emailsSentObject[userEmailSent.uid].push(userEmailSent);
            })
        }
        let users = snapshots[2].val() || {};
        let prizeRedemptions = {};
        if(snapshots[3].exists()){
            snapshots[3].forEach(function(data) {
                const redemptionObject = data.val();
                if(!prizeRedemptions[redemptionObject.uid]){
                    prizeRedemptions[redemptionObject.uid] = [];
                }
                prizeRedemptions[redemptionObject.uid].push(redemptionObject);
            })
        }
        if(searchData){
            refToGoOver = emailsSentRef;
            usersAnswerObject = userAnswersRef.val() || {};
        } else {
            refToGoOver = userAnswersRef;
        }
        refToGoOver.forEach(function(userAnswerDataRef){
            let answerObject = {};
            // let userId = userAnswerDataRef.key;
            let userAnswerObject = userAnswerDataRef.val() || {};
            let userId = userAnswerObject['uid'] || userAnswerDataRef.key;
            let userEmail = "No Email Collected";
            if(users[userId] && users[userId].email) {
                userEmail = users[userId].email
            } else {
                userEmail = userId;
            }
            answerObject['email'] = userEmail;
            answerObject['uid'] = userId;
            if(searchData){
                answerObject['bingo'] = usersAnswerObject[userId].won ? "Yes" : "No";
                if(usersAnswerObject[userId].timeStamp){
                    answerObject['timeStamp'] = usersAnswerObject[userId].timeStamp;
                }
            } else {
                answerObject['bingo'] = userAnswerObject.won ? "Yes" : "No";
                if(userAnswerObject.timeStamp){
                    answerObject['timeStamp'] = userAnswerObject.timeStamp;
                }
            }
            if(searchData) {
                answerObject['rewardSent'] = userAnswerObject.rewardName || userAnswerObject.name || "";
                answerObject['code'] = userAnswerObject.code || "";
                if(!numberOfResults || playedUsers.length < numberOfResults){
                    playedUsers.push(answerObject);
                }
            } else {
                const emailsSent = emailsSentObject[userId];
                if (emailsSent) {
                    let countOfEmailsSent = 0;
                    for(const i in emailsSent){
                        const userEmailSent = emailsSent[i];

                        if(emailsSent.length > 1 && countOfEmailsSent > 0){
                            let anotherAnswerObject = JSON.parse(JSON.stringify(answerObject));
                            anotherAnswerObject['rewardSent'] = userEmailSent.rewardName || userEmailSent.name || "";
                            anotherAnswerObject['code'] = userEmailSent.code || "";
                            const redeemedPrize = prizeRedemptions[userId] || {};
                            if(redeemedPrize.length > 0){
                                for(const t in redeemedPrize){
                                    if(redeemedPrize[t].prizeWonId === userEmailSent.prizeWonId){
                                        anotherAnswerObject['isRedeemed'] = redeemedPrize[t].redeemed;
                                        anotherAnswerObject['prizeWonId'] = redeemedPrize[t].sendPrizeId;
                                    }
                                }
                            }
                            if(!numberOfResults || playedUsers.length < numberOfResults) {
                                playedUsers.push(anotherAnswerObject);
                            }
                        } else {
                            answerObject['rewardSent'] = userEmailSent.rewardName || userEmailSent.name || "";
                            answerObject['code'] = userEmailSent.code || "";
                            const redeemedPrize = prizeRedemptions[userId] || {};
                            if(redeemedPrize.length > 0){
                                for(const t in redeemedPrize){
                                    if(redeemedPrize[t].prizeWonId === userEmailSent.prizeWonId){
                                        answerObject['isRedeemed'] = redeemedPrize[t].redeemed;
                                        answerObject['prizeWonId'] = redeemedPrize[t].sendPrizeId;
                                    }
                                }
                            }
                            if(!numberOfResults || playedUsers.length < numberOfResults) {
                                playedUsers.push(answerObject);
                            }
                        }
                        countOfEmailsSent++
                    }
                } else {
                    if(!numberOfResults || playedUsers.length < numberOfResults) {
                        playedUsers.push(answerObject);
                    }
                }
            }
        })

        playedUsers.sort(function(a,b){
            return a.bingo - b.bingo
        });
        return playedUsers
    }

    async downloadUsers() {
        this.setState({loading: true});
        const vm = this;
        let csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Country,Bingo,Reward Sent,Code,Redeemed\n';
        Promise.all([get(ref(database, 'users'))]).then(async function (snapshots) {
            let users = {};
            if (snapshots[0].exists() && snapshots[0].val()) {
                users = snapshots[0].val();
            }
            const currentUsersState = await vm.organizeUserAnswers(null, null, vm.state.currentGame);
            currentUsersState.forEach(function (row) {
                let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
                csv += (users[row.uid].email || row.uid || "");
                csv += ",";
                csv += (cleanCSVText(firstName) || "");
                csv += ",";
                csv += (cleanCSVText(secondName) || "");
                csv += ",";
                csv += (users[row.uid].phoneNumber || "");
                csv += ",";
                csv += (cleanCSVText(users[row.uid].zipCode) || "");
                csv += ",";
                csv += (cleanCSVText(users[row.uid].address) || "");
                csv += ",";
                csv += (users[row.uid].birthday || "");
                csv += ",";
                csv += (users[row.uid].optIn || "");
                csv += ",";
                csv += (users[row.uid].country || "");
                csv += ",";
                csv += (row.bingo || "");
                csv += ",";
                csv += (cleanCSVText(row.rewardSent) || "NONE");
                csv += ",";
                csv += (cleanCSVText(row.code) || "-");
                csv += ",";
                csv += (row.isRedeemed ? "true" : "false");
                csv += "\n";
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            hiddenElement.target = '_blank';
            const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split("T")[0] + ".csv";
            hiddenElement.download = "current_game_users_" + date_got;
            hiddenElement.click();
            vm.setState({loading: false});
        });
    }

    componentWillUnmount() {
        off(ref(database, 'userAnswers'));
        off(ref(database, 'emailsSent'));
        off(ref(database, 'currentGame'));
    }

    detectHowManyCodesEntered(codesArray) {
        if (!codesArray || codesArray === []) {
            return [];
        }
        codesArray = codesArray.trim();
        const splitOnLineBreaks = codesArray.split("\n");
        let splitOnWhiteSpace = codesArray.split(/\s+/);
        let splitOnCommas = codesArray.split(",");
        let splitArray = splitOnLineBreaks;
        if (splitOnWhiteSpace.length === splitOnCommas.length) {
            splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
            splitOnCommas = codesArray.replace(/\s/g, '').split(",");
        }
        if (splitArray.length < splitOnWhiteSpace.length) {
            splitArray = splitOnWhiteSpace
        }
        if (splitArray.length < splitOnCommas.length) {
            splitArray = splitOnCommas
        }
        return splitArray
    }

    determineMaxNumberOfSquaresCompletedAllowed(boardSize, totalSquares) {
        const integerBoardSize = parseInt(boardSize);
        switch (integerBoardSize) {
            case 1:
                return totalSquares - boardSize
            case 4:
                return 1 + (totalSquares - boardSize)
            case 9:
                return 6 + (totalSquares - boardSize)
            default:
                const minimumForBingo = Math.sqrt(integerBoardSize);
                //in general each row with 1 unmarked square can be arranged so no bingo is made, but we need to add the total number of squares subtracted from the bingo square size because then we can send more completed squares
                return (integerBoardSize - Math.floor(minimumForBingo) + (totalSquares - boardSize));
        }
    }

    async createGame() {
        let createGameObject = {};
        const gameId = push(ref(database, 'games')).key;
        const futureGames = this.state.futureGamesList || [];
        const itemsAdded = this.state.itemsAdded || [];
        let itemsScoreboardAdded = this.state.itemsScoreboardAdded || [];
        let bingoCardSize = parseInt(this.state.bingoCardSize || 0);
        if (!bingoCardSize || bingoCardSize < 1 || Math.sqrt(bingoCardSize) % 1 !== 0) {
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'Need a size of your bingo card that is a square. 1, 4, 9 etc are all valid numbers of squares to add.'
            });
            return;
        } else if (itemsAdded.length < bingoCardSize) {
            //plus 1 represents the default square being there
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'You need to add more squares to fill your bingo board'
            });
            return
        }
        if (!itemsAdded) {
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'Need to bingo squares'
            });
            return
        }
        for (const i in itemsAdded) {
            if (itemsAdded[i].defaultCenter && (bingoCardSize < 9 || bingoCardSize % 2 === 0)) {
                itemsAdded[i].defaultCenter = null;
            }
        }
        if (this.state.activateScoreboard) {
            if (!itemsScoreboardAdded || itemsScoreboardAdded.length === 0) {
                await ErrorMessage.fire({
                    title: "Hold On!",
                    text: 'Need to add SCOREBOARD bingo squares or uncheck the create admin board checkbox'
                });
                return
            } else if (Math.sqrt(itemsScoreboardAdded.length) % 1 !== 0) {
                await ErrorMessage.fire({
                    title: "Hold On!",
                    text: 'Need a size of your SCOREBOARD bingo card that is a square. 1, 4, 9 etc are all valid numbers of squares to add.'
                });
                return;
            }
        } else {
            itemsScoreboardAdded = [];
        }
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        if (!startTime || !endTime) {
            ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game needs a start time and end time!'
            });
            return
        }
        startTime = startTime.getTime();
        endTime = endTime.getTime();
        if (startTime >= endTime) {
            ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game start time cannot be equal to or greater than the game end time!'
            });
            return
        }
        if (startTime <= Date.now()) {
            ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game start time cannot be before now!'
            });
            return
        }
        for (const fGameIndex in futureGames) {
            const futureGame = futureGames[fGameIndex];
            if (startTime === futureGame.scheduleInfo.performAt) {
                ErrorMessage.fire({
                    title: "Hold On!",
                    text: 'You already have a scheduled game at that time!'
                });
                return;
            }
        }
        let gameName = this.state.gameName.trim();
        let {rewardsAdded, rewardsScoreboardAdded} = this.state;
        if (!gameName) {
            ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game needs a name!'
            });
            return
        }
        if (!this.state.activateScoreboard) {
            rewardsScoreboardAdded = [];
        }
        let readyToUploadPrizes = null;
        let readyToUploadLosingPrizes = null;
        let hasRewardWithAutoRedemptions = false;
        const rewardsToCreateArray = [rewardsAdded, rewardsScoreboardAdded];
        for (const i in rewardsToCreateArray) {
            const arrayToReview = rewardsToCreateArray[i];
            if (arrayToReview && arrayToReview.length !== 0) {
                let totalAmount = 0;
                let prepareToBeReadyToUploadPrizes = {};
                for (const rewardIndex in arrayToReview) {
                    prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key] = JSON.parse(JSON.stringify(arrayToReview[rewardIndex].reward));
                    prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key]['amount'] = parseInt(arrayToReview[rewardIndex].amount);
                    totalAmount += parseInt(arrayToReview[rewardIndex].amount);
                    prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key]['codes'] = arrayToReview[rewardIndex].codes;
                    prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key]['qrCodes'] = arrayToReview[rewardIndex].qrCodes;
                    prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key]['codesArray'] = arrayToReview[rewardIndex].codesArray || [];
                    prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key]['used'] = 0;
                    prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key]['isRedeemable'] = arrayToReview[rewardIndex].reward.isRedeemable || false;
                    if (prepareToBeReadyToUploadPrizes[arrayToReview[rewardIndex].reward.key]['isRedeemable']) {
                        hasRewardWithAutoRedemptions = true;
                    }
                }
                prepareToBeReadyToUploadPrizes["totalRewards"] = totalAmount;
                prepareToBeReadyToUploadPrizes["totalRewardsUsed"] = 0;
                prepareToBeReadyToUploadPrizes["randomPrizeOrder"] = parseInt(i) === 0 ? this.state.randomOrder : this.state.randomScoreboardOrder;
                if (parseInt(i) === 0) {
                    readyToUploadPrizes = prepareToBeReadyToUploadPrizes
                } else {
                    readyToUploadLosingPrizes = prepareToBeReadyToUploadPrizes;
                }
            }
        }
        this.setState({loading: true})
        createGameObject.gameName = gameName;
        createGameObject.active = false;
        createGameObject.ended = false;
        createGameObject.gameType = "bingo";
        createGameObject.rewards = readyToUploadPrizes;
        createGameObject.scoreboardRewards = readyToUploadLosingPrizes;
        createGameObject.bingoCardSize = bingoCardSize;
        createGameObject.bingoCardSizeSqrt = parseInt(Math.sqrt(bingoCardSize));
        createGameObject.maxNumberOfSquaresCompletedAllowed = this.determineMaxNumberOfSquaresCompletedAllowed(bingoCardSize, itemsAdded.length);
        createGameObject.totalSquares = itemsAdded.length;
        createGameObject.bingoSquaresCompleted = null;
        createGameObject.bingoAdminSquaresCompleted = null;
        createGameObject.scoreboardBingoMade = null;
        createGameObject.scheduleInfo = {};
        createGameObject.scheduleInfo['status'] = 'scheduled';
        createGameObject.scheduleInfo['performAt'] = startTime;
        createGameObject.scheduleInfo['endAt'] = endTime;
        createGameObject.bingoSquares = itemsAdded;
        createGameObject.bingoSquaresScoreboard = itemsScoreboardAdded;
        createGameObject.hasRewardWithAutoRedemptions = hasRewardWithAutoRedemptions;
        let currentGame = this.state.currentGame || {};
        createGameObject.id = gameId;
        if (currentGame.gameName) {
            const vm = this;
            await set(ref(database, `futureGamesList/${gameId}`), createGameObject);
            const futureGameList = await vm.getFutureGames();
            vm.setState({
                modal: false,
                futureGamesList: futureGameList,
                loading: false
            });
            await Toast.fire({
                title: 'Game added to future games!'
            });
        } else {
            createGameObject.timeStamp = Date.now();
            const currentGameRef = ref(database, 'currentGame');
            await update(currentGameRef, createGameObject);
            this.setState({
                currentGame: createGameObject,
                modal: false,
                loading: false,
                currentUserAnswersState: [],
                rewardsScoreboardAdded: [],
                rewardsAdded: []
            }, () => {
                this.getRedemptions(gameId);
            });
        }
    }

    async makeFutureGameCurrent(gameToSetCurrent) {
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
        if (process.env.NODE_ENV === "development") {
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({futureGame: gameToSetCurrent, futureGameKey: gameToSetCurrent.id})
        }
        let response;
        try {
            response = await fetch(url, fetchObject);
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
            return false;
        }
        const responseJson = await Promise.resolve(response.json());
        if (responseJson.error) {
            this.setState({loading: false});
            await ErrorMessage.fire({
                title: 'Oh No!',
                text: "Something went wrong, please try again!"
            });
            return;
        }
        const futureGameList = await this.getFutureGames();
        await this.getRedemptions(gameToSetCurrent.id);
        this.setState({
            futureGamesList: futureGameList,
            loading: false
        });
        await Toast.fire({
            title: 'Game made current!'
        });
    }

    async removeGameFromFutureList(gameToRemoveId) {
        const futureGameList = this.state.futureGamesList.filter(game => game.id !== gameToRemoveId);
        await remove(ref(database, `futureGamesList/${gameToRemoveId}`));
        this.setState({
            futureGamesList: futureGameList
        });
    }

    async stopGame() {
        await this.setState({loading: true});
        await set(ref(database, "currentGame/active"), false);
        await this.setState({loading: false});
        await Toast.fire({
            title: 'Game Stopped'
        });
    }

    async startGame() {
        await this.setState({loading: true});
        const updateObject = {};
        updateObject["currentGame/active"] = true;
        updateObject["currentGame/timeStamp"] = Date.now();
        await update(ref(database), updateObject);
        await this.setState({loading: false});
        await Toast.fire({
            title: 'Game Started'
        });
    }

    addItemToLocalArray(isAdmin = false) {
        let selected_bingo_square = null;
        const stateVerb = isAdmin ? "selected_admin_bingo_square" : "selected_bingo_square";
        const itemsAddedName = isAdmin ? "itemsScoreboardAdded" : "itemsAdded";
        const itemsAddedArray = this.state[itemsAddedName] || [];
        const itemsList = this.state.itemsList;
        const setToCurrentDefault = this.state.setToCurrentDefault;
        let currentDefaultFound = false;
        for (const matchAddedIndex in itemsAddedArray) {
            if (itemsAddedArray[matchAddedIndex].id === this.state[stateVerb]) {
                ErrorMessage.fire({
                    title: 'Already added!',
                    text: "This has already been added!"
                });
                return;
            }
            if (itemsAddedArray[matchAddedIndex].defaultCenter) {
                currentDefaultFound = true;
            }
        }
        if (currentDefaultFound && setToCurrentDefault) {
            ErrorMessage.fire({
                title: 'Oh No!',
                text: "There has already been a square added that is default center!"
            });
            return;
        }
        for (const matchIndex in itemsList) {
            if (itemsList[matchIndex].id === this.state[stateVerb]) {
                selected_bingo_square = itemsList[matchIndex];
                selected_bingo_square['id'] = itemsList[matchIndex].id || itemsList[matchIndex].key;
                if (setToCurrentDefault) {
                    selected_bingo_square['defaultCenter'] = setToCurrentDefault;
                }
            }
        }
        if (selected_bingo_square) {
            itemsAddedArray.push(selected_bingo_square);
            this.setState({
                [itemsAddedName]: itemsAddedArray
            });
        }
    }

    handleChange(evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[target.name]: value});
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            wizardLocation: "first",
            itemsAdded: [],
            gameName: "",
            rewardsAdded: [],
            codes: false,
            rewardToAdd: '',
            rewardAmount: 0,
            codesArray: '',
            qrCodes: false,
        });
    }

    async resetGame() {
        const currentGame = this.state.currentGame;
        const result = await WarningMessage.fire({
            title: 'STOP!',
            text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
            confirmButtonText: 'YES I WANT TO RESET THE GAME'
        });
        if (result.value) {
            await this.setState({loading: true});
            let currentGameState = this.state.currentGame || {};
            const updateGameObject = {};
            updateGameObject['currentGame/active'] = false;
            if (currentGameState.rewards) {
                updateGameObject['currentGame/rewards/totalRewardsUsed'] = 0;
                updateGameObject['currentGame/scoreboardRewards/totalRewardsUsed'] = 0;
            }
            for (const index in currentGameState.rewards) {
                if (typeof currentGameState.rewards[index] === 'object') {
                    if (index) {
                        updateGameObject['currentGame/rewards/' + index + '/used'] = 0;
                    }
                }
            }
            for (const index in currentGameState.scoreboardRewards) {
                if (typeof currentGameState.scoreboardRewards[index] === 'object') {
                    if (index) {
                        updateGameObject['currentGame/scoreboardRewards/' + index + '/used'] = 0;
                    }
                }
            }
            for (const index in currentGameState.bingoSquares) {
                updateGameObject['currentGame/bingoSquares/' + index + '/completed'] = false;
            }
            for (const index in currentGameState.bingoSquaresScoreboard) {
                updateGameObject['currentGame/bingoSquaresScoreboard/' + index + '/completed'] = false;
            }
            updateGameObject['currentGame/bingoSquaresCompleted'] = null;
            updateGameObject['currentGame/bingoAdminSquaresCompleted'] = null;
            updateGameObject['currentGame/scoreboardBingoMade'] = null;
            const redemptionsQuery = query(
                ref(database, 'redeemedPrize'),
                orderByChild('gameId'),
                equalTo(currentGameId)
            );

            const snapshot = await get(redemptionsQuery);
            const redemptions = snapshot.val();
            for (let redemption in redemptions) {
                let refInstance = redemptions[redemption];
                if (refInstance && refInstance.gameId === currentGame.id && currentGame.id) {
                    await remove(ref(database, `redeemedPrize/${redemption}`));
                }
            }
            await Promise.all([
                set(ref(database, "emailsSent"), null),
                set(ref(database, "userAnswers"), null),
                update(ref(database), updateGameObject)
            ]);
            this.setState({
                currentUserAnswersState: [],
                loading: false
            });
            await Toast.fire({
                title: 'Game Reset'
            });
        }
    }

    navButtonClicked(direction) {
        const currentLocation = this.state.wizardLocation;
        if (direction === 'prev' && currentLocation === 'second') {
            this.setState({
                wizardLocation: 'first'
            });
            this.toggleWizardLocation('first', 'second', 'third', 'fourth', 'fifth')
        } else if (direction === 'prev' && currentLocation === 'third') {
            this.setState({
                wizardLocation: 'second'
            });
            this.toggleWizardLocation('second', 'first', 'third', 'fourth', 'fifth')
        } else if (direction === 'next' && currentLocation === 'first') {
            this.setState({
                wizardLocation: 'second',
            });
            this.toggleWizardLocation('second', 'first', 'third', 'fourth', 'fifth')
        } else if (direction === 'next' && currentLocation === 'second') {
            this.setState({
                wizardLocation: 'third',
            });
            this.toggleWizardLocation('third', 'first', 'second', 'fourth', 'fifth')
        } else if (direction === 'prev' && currentLocation === 'fourth') {
            this.setState({
                wizardLocation: 'third',
            });
            this.toggleWizardLocation('third', 'first', 'second', 'third', 'fifth')
        } else if (direction === 'next' && currentLocation === 'third') {
            this.setState({
                wizardLocation: 'fourth',
            });
            this.toggleWizardLocation('fourth', 'first', 'second', 'third', 'fifth')
        } else if (direction === 'prev' && currentLocation === 'fifth') {
            this.setState({
                wizardLocation: 'fourth',
            });
            this.toggleWizardLocation('fourth', 'first', 'second', 'third', 'fifth')
        } else if (direction === 'next' && currentLocation === 'fourth') {
            this.setState({
                wizardLocation: 'fifth',
            });
            this.toggleWizardLocation('fifth', 'first', 'fourth', 'third', 'second')
        } else {
            this.setState({
                wizardLocation: 'first'
            });
            this.toggleWizardLocation('first', 'second', 'third', 'fourth', 'fifth')
        }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2, otherTab3, otherTab4) {
        this.setState({
            wizardLocation: tabClicked
        });
        document.getElementById(tabClicked).classList.add('active');
        document.getElementById(tabClicked).classList.add('show');
        document.getElementById(otherTab1).classList.remove('active');
        document.getElementById(otherTab1).classList.remove('show');
        document.getElementById(otherTab2).classList.remove('active');
        document.getElementById(otherTab2).classList.remove('show');
        document.getElementById(otherTab3).classList.remove('active');
        document.getElementById(otherTab3).classList.remove('show');
        document.getElementById(otherTab4).classList.remove('active');
        document.getElementById(otherTab4).classList.remove('show');
        document.getElementById(tabClicked + '1').classList.add('active');
        document.getElementById(tabClicked + '1').classList.add('show');
        document.getElementById(otherTab1 + '1').classList.remove('active');
        document.getElementById(otherTab1 + '1').classList.remove('show');
        document.getElementById(otherTab2 + '1').classList.remove('active');
        document.getElementById(otherTab2 + '1').classList.remove('show');
        document.getElementById(otherTab3 + '1').classList.remove('active');
        document.getElementById(otherTab3 + '1').classList.remove('show');
        document.getElementById(otherTab4 + '1').classList.remove('active');
        document.getElementById(otherTab4 + '1').classList.remove('show');
    }

    async getFutureGames(numberOfResults = 10) {
        const snapshot = await get(ref(database, 'futureGamesList'));
        return snapshot.exists() ? Object.values(snapshot.val()) : [];
    }

    async switchStatsPrizes(buttonClicked) {
        if (buttonClicked === "showStats") {
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats: true,
                showPrizes: false,
                showGames: false
            });
        } else if (buttonClicked === "showPrizes") {
            document.getElementById('showPrizes').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            this.setState({
                loading: true
            });
            const result = await this.organizeUserAnswers(this.state.responseSize, null, this.state.currentGame);
            this.setState({
                showStats: false,
                showPrizes: true,
                showGames: false,
                loading: false,
                currentUserAnswersState: result
            });
        } else if (buttonClicked === "showGames") {
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            const futureGameList = await this.getFutureGames();
            this.setState({
                showStats: false,
                showPrizes: false,
                showGames: true,
                futureGamesList: futureGameList
            });
        }
    }

    addRewardToLocalArray(){
        const rewardAmount = this.state.rewardAmount;
        const rewardId = this.state.rewardToAdd;
        let codes = this.state.codes;
        let qrCodes = this.state.qrCodes;
        let codesArray = this.state.codesArray;
        if(!codes){
            qrCodes = false;
            codesArray = "";
        }
        codesArray = this.detectHowManyCodesEntered(codesArray);
        if(codesArray.length === 0 && codes){
            ErrorMessage.fire({
                title: 'Hold on!',
                text: 'Must enter codes or uncheck codes box!'
            })
            return;
        } else if (codes){
            const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
            if(isMlbApp){
                let codesTooLong = false;
                for(const individualCode in codesArray){
                    if(codesArray[individualCode].length > 100){
                        codesTooLong = true;
                    }
                }
                if(codesTooLong){
                    ErrorMessage.fire({
                        title: 'Hold on!',
                        text: 'Individual codes must be less than 100 characters'
                    })
                    return;
                }
            }

            if(codesArray.length !== parseInt(rewardAmount,10)){
                ErrorMessage.fire({
                    title: 'Codes not equal!',
                    text: "# of codes must equal number of rewards created. In this case: " +  codesArray.length + " does not equal " + rewardAmount
                })
                return;
            }
        }

        if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
            ErrorMessage.fire({
                title: 'Missing field!',
                text: "Missing amount of reward.  Can't add a reward to a game without an amount",
            })
            return;
        }
        const rewardToAddObject = {};
        for(var ticket in this.state.ticketList){
            if(this.state.ticketList[ticket].id === rewardId){
                var reward = this.state.ticketList[ticket]
                rewardToAddObject.reward = reward;
                rewardToAddObject.amount = rewardAmount;
                rewardToAddObject.codes = codes;
                rewardToAddObject.qrCodes = qrCodes;
                rewardToAddObject.codesArray = codesArray;
            }
        }

        let array = this.state.rewardsAdded;
        let arrayName = 'rewardsAdded';
        if(!this.state.showPersonalPrizes){
            array = this.state.rewardsScoreboardAdded || [];
            arrayName = 'rewardsScoreboardAdded';
        }
        var rewardsAddedIds = []
        for(var prize in array){
            rewardsAddedIds.push(array[prize].reward.key)
        }
        if(rewardsAddedIds.indexOf(rewardId) === -1){
            array = array.concat(rewardToAddObject)
            this.setState({
                [arrayName]: array,
                qrCodes: false,
                codes: false
            })
        } else {
            ErrorMessage.fire({
                title: 'Prize already added!',
                text: "You have already added this prize"
            })
        }
    }

    removeFromToAddArray(index, arrayName) {
        var rewardArray = this.state[arrayName];
        rewardArray.splice(index, 1);
        this.setState({
            [arrayName]: rewardArray
        });
    }

    async markCompleted(item) {
        const responseToPopUp = await WarningMessage.fire({
            title: 'Mark Complete?',
            text: 'This will mark the square completed and send out prizes if a player achieves Bingo',
            confirmButtonText: 'Mark Completed'
        });
        if (!responseToPopUp || !responseToPopUp.value) return;
        this.setState({loading: true});
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/markBingoSquareCompleted`;
        if (process.env.NODE_ENV === "development") {
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/markBingoSquareCompleted`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({squareToMark: item, mlbTeamId: process.env.REACT_APP_MLB_TEAM_ID})
        };
        let response;
        try {
            response = await fetch(url, fetchObject);
            response = await response.json();
        } catch (e) {
            console.log("ERROR: ", e);
            this.setState({loading: false});
            await ErrorMessage.fire({
                title: "Uh Oh!",
                text: 'Something went wrong, check your connection and try again!'
            });
            return
        }
        this.setState({loading: false});
        if (response.result === "success") {
            await Toast.fire({title: 'Successfully Marked!'})
        }
    }

    async handleLoadMore(page) {
        const anotherResponse = await this.organizeUserAnswers(this.state.responseSize * page, document.getElementById('searchQuery').value, this.state.currentGame);
        const pageNumberAccurate = this.state.responseSize * page / this.state.responseSize;
        this.setState({
            currentUserAnswersState: anotherResponse,
            page: pageNumberAccurate
        });
    }

    async setRewardRedeemed(reward, index) {
        const result = await WarningMessage.fire({
            title: 'Hold on!',
            text: 'This prizes redemption process is handled automatically by Sqwad, are you sure you want to toggle this?',
            confirmButtonText: 'Toggle'
        });
        if (!result.value) {
            return
        }
        let uid = reward.uid || "";
        const currentGame = this.state.currentGame || {};
        const gameId = currentGame.id || "";
        const prizeWonId = reward.prizeWonId || reward.sendPrizeId || "";
        if (!uid || !gameId || !prizeWonId) {
            return
        }
        this.setState({
            loading: true
        });
        let valueToSet = false;
        const redeemedPrizeRef = await get(ref(database, `redeemedPrize/${prizeWonId}`));
        const redeemedPrize = redeemedPrizeRef.val();
        if (redeemedPrize.redeemed) {
            await update(ref(database, `redeemedPrize/${prizeWonId}`), {redeemed: false, timeRedeemed: null});
        } else {
            valueToSet = true;
            await update(ref(database, `redeemedPrize/${prizeWonId}`), {redeemed: true, timeRedeemed: new Date().getTime()});
        }
        const currentUserAnswersState = this.state.currentUserAnswersState;
        currentUserAnswersState[index].isRedeemed = valueToSet;
        this.setState({
            currentUserAnswersState: currentUserAnswersState,
            loading: false
        });
    }

    async addAllSquaresToGame() {
        const result = await WarningMessage.fire({
            title: 'Hold on!',
            text: 'This will add all squares to the game, are you sure you want to do this?',
            confirmButtonText: 'Add All'
        });
        if (!result.value) return;
        const itemsAddedArray = [];
        const itemsList = this.state.itemsList;
        for (const matchIndex in itemsList) {
            itemsAddedArray.push(itemsList[matchIndex]);
        }
        this.setState({
            itemsAdded: itemsAddedArray
        });
    }

    async toggleScratching(e) {
        e.preventDefault();
        this.setState({loading: true});
        try {
            await set(ref(database, 'tenantVariables/scratchingTurnedOff'), !this.state.scratchingTurnedOff);
            this.setState({scratchingTurnedOff: !this.state.scratchingTurnedOff, loading: false});
        } catch (e) {
            this.setState({loading: false});
            await ErrorMessage.fire({
                title: "Uh Oh!",
                text: 'Something went wrong, check your connection and try again!'
            });
        }
    }

    render() {
        const itemsAdded = this.state.itemsAdded;
        const itemsList = this.state.itemsList || [];
        const itemsScoreboardAdded = this.state.itemsScoreboardAdded || [];
        const futureGamesList = this.state.futureGamesList || [];
        let selectedGame = this.state.currentGame || null;
        let rewardsAdded = this.state.rewardsAdded || [];
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        if (!this.state.showPersonalPrizes) {
            rewardsAdded = this.state.rewardsScoreboardAdded || [];
        }
        let rewardsList = [];
        let gameName = null;
        let currentUserAnswersState = this.state.currentUserAnswersState || [];
        let userAnswersCount = this.state.userAnswerCount || 0;
        let userAnswerWinnerCount = this.state.userAnswerWinnerCount || 0;
        let userWinnersCount = this.state.userWinnersCount || 0;
        let startTimeFirstPart = null;
        let startTimeSecondPart = null;
        let endTimeSecondPart = null;
        let endTimeFirstPart = null;
        let gameScheduledStatus = "completed";
        let randomOrder = false;
        let randomOrderName = "randomOrder";
        let totalSquares = [];
        let hasRewardWithAutoRedemptions = false;
        let currentSetToDefault = false;
        if (selectedGame && selectedGame.gameName) {
            let rewardsToGoOver = this.state.showIndividualRewards ? selectedGame.rewards : selectedGame.scoreboardRewards;
            for (const i in rewardsToGoOver) {
                const reward = rewardsToGoOver[i];
                if (typeof reward === "object") {
                    rewardsList.push(reward);
                }
            }
            if (selectedGame.scheduleInfo) {
                startTimeFirstPart = new Date(selectedGame.scheduleInfo.performAt).toLocaleDateString();
                startTimeSecondPart = new Date(selectedGame.scheduleInfo.performAt).toLocaleTimeString();
                endTimeFirstPart = new Date(selectedGame.scheduleInfo.endAt).toLocaleDateString();
                endTimeSecondPart = new Date(selectedGame.scheduleInfo.endAt).toLocaleTimeString();
                gameScheduledStatus = selectedGame.scheduleInfo.status
            }
            hasRewardWithAutoRedemptions = selectedGame.hasRewardWithAutoRedemptions;
            let bingoSquaresScoreboard = selectedGame.bingoSquaresScoreboard || [];
            let bingoSquares = selectedGame.bingoSquares || [];
            const bingoSquareIds = [];
            for (const i in bingoSquares) {
                totalSquares.push(bingoSquares[i]);
                bingoSquareIds.push(bingoSquares[i].id);
            }
            if (bingoSquaresScoreboard.length > 0) {
                for (const i in bingoSquaresScoreboard) {
                    const indexOfBingoSquare = bingoSquareIds.indexOf(bingoSquaresScoreboard[i].id);
                    if (indexOfBingoSquare === -1) {
                        bingoSquaresScoreboard[i].adminOnly = true;
                        totalSquares.push(bingoSquaresScoreboard[i]);
                    } else if (indexOfBingoSquare !== -1) {
                        totalSquares[indexOfBingoSquare].fanAndAdmin = true;
                    }
                }
            }
            gameName = selectedGame.gameName;
            randomOrder = this.state.showPersonalPrizes ? this.state.randomOrder : this.state.randomScoreboardOrder;
            randomOrderName = this.state.showPersonalPrizes ? "randomOrder" : "randomScoreboardOrder";
        } else {
            selectedGame = null;
        }
        let sizeOfBingoSquares = 25;
        if (itemsScoreboardAdded && itemsScoreboardAdded.length > 0) {
            const numberOfSquaresInRow = parseInt(Math.sqrt(itemsScoreboardAdded.length));
            const numberOfGutters = numberOfSquaresInRow - 1;
            const marginSpace = numberOfGutters * 5;
            sizeOfBingoSquares = (75 - marginSpace) / numberOfSquaresInRow;
        }
        if (itemsAdded && itemsAdded.length > 0) {
            for (const i in itemsAdded) {
                if (itemsAdded[i].defaultCenter) {
                    currentSetToDefault = true;
                }
            }
        }
        const vm = this;
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none'}}/>
                <SideMenu/>
                <TopMenu/>
                <div className="admin-main-panel">
                    <div className="container" style={{padding: '20px', backgroundColor: '#e3eaef'}}>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card"
                                     style={{
                                         backgroundColor: '#00c78c',
                                         width: '100%',
                                         textAlign: 'center',
                                         height: '50px',
                                         display: selectedGame && selectedGame.active ? '' : 'none',
                                         float: 'left'
                                     }}>
                                    <p style={{lineHeight: '50px'}}>Game Live</p>
                                </div>
                                <div className="card"
                                     style={{
                                         backgroundColor: '#fe3b4b',
                                         width: '100%',
                                         textAlign: 'center',
                                         height: '50px',
                                         display: selectedGame && !selectedGame.active ? '' : 'none',
                                         float: 'left'
                                     }}>
                                    <p style={{lineHeight: '50px'}}>Game Not Live</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button onClick={() => this.stopGame()}
                                        className="btn btn-primary btn-lg end-game-button"
                                        style={{
                                            display: selectedGame && selectedGame.active ? '' : 'none',
                                            float: 'left',
                                            height: '52px'
                                        }}>Stop Game
                                </button>
                                <button onClick={() => this.startGame()}
                                        className="btn btn-primary btn-lg start-game-button"
                                        style={{
                                            display: selectedGame && !selectedGame.active ? '' : 'none',
                                            float: 'left',
                                            height: '52px'
                                        }}>Start Game
                                </button>
                            </div>
                            <div className="col-md-6" style={{textAlign: 'right'}}>
                                <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()}
                                   style={{display: selectedGame ? '' : 'none', marginTop: '7px', float: 'right'}}>Reset
                                    Game</a>
                                <button onClick={() => this.toggle()}
                                        className="btn btn-primary btn-lg create-game-button"
                                        style={{float: 'right', marginRight: '5px'}}>New Game
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{display: !selectedGame ? 'none' : 'block'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4"
                                     style={{
                                         color: "black",
                                         border: "solid 1px #d3d3d3",
                                         margin: 10,
                                         borderRadius: 5
                                     }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <u style={{color: "#353839"}}>Starts</u>
                                            <br/>
                                            <span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>
                                        </div>
                                        <div className="col-md-6">
                                            <u style={{color: "#353839"}}>Ends</u>
                                            <br/>
                                            <span style={{fontSize: 18}}>{endTimeFirstPart}<br/>{endTimeSecondPart}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4"
                                     style={{
                                         color: "black",
                                         border: "solid 1px #d3d3d3",
                                         marginTop: 10,
                                         marginBottom: 10,
                                         borderRadius: 5
                                     }}>
                                    <div className="row">
                                        <div className="col-3" style={{textAlign: 'center'}}>
                                            <span style={{fontSize: 12}}>Bingo Boards</span>
                                            <footer className="value-text">{userAnswersCount}</footer>
                                        </div>
                                        <div className="col-3" style={{textAlign: 'center'}}>
                                            <span style={{fontSize: 12}}>Bingos</span>
                                            <footer className="value-text">{userAnswerWinnerCount}</footer>
                                        </div>
                                        <div className="col-3" style={{textAlign: 'center'}}>
                                            <span style={{fontSize: 12}}>Prizes Won</span>
                                            <footer className="value-text">{userWinnersCount}</footer>
                                        </div>
                                        {hasRewardWithAutoRedemptions &&
                                            <div className="col-3" style={{textAlign: 'center'}}>
                                                <span style={{fontSize: 12}}>Redemptions</span>
                                                <footer className="value-text">{this.state.redemptions || 0}</footer>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{color: 'black'}}>
                        <div className="row">
                            <div className="form-check" style={{marginBottom: 20}} align="left">
                                <input readOnly={true} value={this.state.scratchingTurnedOff}
                                       className="form-check-input" id="scratchingTurnedOff" name="scratchingTurnedOff"
                                       type="checkbox" checked={this.state.scratchingTurnedOff}
                                       onChange={(e) => this.toggleScratching(e)}/>
                                <label className="form-check-label" htmlFor="setToCurrentDefault">Turn Scratching
                                    Off</label>
                            </div>
                        </div>
                    </div>
                    <div style={{display: !selectedGame ? 'none' : 'block'}}>
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                <li className="nav-item" onClick={() => this.switchStatsPrizes("showStats")}>
                                    <a href="#" data-toggle="tab" aria-expanded="false"
                                       className="nav-link active" id="showStats"
                                       style={{backgroundColor: '#fafbfe'}}>
                                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                        <span className="d-none d-lg-block">Game Details</span>
                                    </a>
                                </li>
                                <li className="nav-item" onClick={() => this.switchStatsPrizes("showPrizes")}>
                                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link"
                                       id="showPrizes" style={{backgroundColor: '#fafbfe'}}>
                                        <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                        <span className="d-none d-lg-block">Results</span>
                                    </a>
                                </li>
                                <li className="nav-item" onClick={() => this.switchStatsPrizes("showGames")}>
                                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link"
                                       id="showGames" style={{backgroundColor: '#fafbfe'}}>
                                        <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                        <span className="d-none d-lg-block">Future Games</span>
                                    </a>
                                </li>
                            </ul>
                            <div style={{display: this.state.showPrizes ? 'block' : 'none', marginLeft: 10}}>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="export-button-styles btn btn-primary btn-lg download-button"
                                             onClick={() => this.downloadUsers()}>
                                            <span className="fa fa-arrow-circle-down"/> Download Participants
                                        </div>
                                    </div>
                                    <div className="col-6 align-self-end">
                                        <input style={{minWidth: 350, float: "right"}} type="text" id="searchQuery"
                                               name="search" placeholder="Filter By Prize Name (Case Sensitive)"
                                               onChange={() => {
                                                   const vm = this;
                                                   clearTimeout(this.timeoutId); // no-op if invalid id
                                                   this.timeoutId = setTimeout(async function () {
                                                       let input = document.getElementById('searchQuery').value;
                                                       const response = await vm.organizeUserAnswers(vm.state.responseSize, input, vm.state.currentGame);
                                                       vm.setState({
                                                           currentUserAnswersState: response
                                                       });
                                                   }, 500);
                                               }
                                               }/>
                                    </div>
                                </div>
                                <div style={{height: '10px', width: '100%'}}/>
                                <div style={{height: 700, overflow: "auto"}}>
                                    <InfiniteScroll
                                        loadMore={(page) => this.handleLoadMore(page)}
                                        hasMore={this.state.responseSize * this.state.page === currentUserAnswersState.length}
                                        pageStart={0}
                                        useWindow={false}
                                    >
                                        <table className="table table-striped" style={{color: 'black'}}>
                                            <tbody>
                                            <tr>
                                                <th>{isMlbApp ? "Id" : "Email"}</th>
                                                <th>Bingo?</th>
                                                <th>Play Time</th>
                                                <th>Reward Sent</th>
                                                <th>Code</th>
                                                {hasRewardWithAutoRedemptions &&
                                                    <th>Redeemed</th>
                                                }
                                            </tr>
                                            {
                                                currentUserAnswersState.map(function (item, i) {
                                                    return <tr key={i}>
                                                        <td style={{fontFamily: 'Open Sans'}}>{item.email || item.uid}</td>
                                                        <td style={{fontFamily: 'Open Sans'}}>{item.bingo}</td>
                                                        <td style={{fontFamily: 'Open Sans'}}>{convertTimeStampToHumanReadable(item.timeStamp)}</td>
                                                        <td style={{fontFamily: 'Open Sans'}}>{item.rewardSent || item.name || "NONE"}</td>
                                                        <td style={{fontFamily: 'Open Sans'}}>{item.code || "NONE"}</td>
                                                        {hasRewardWithAutoRedemptions &&
                                                            <td style={{fontFamily: 'Open Sans'}}>
                                                                <input type="checkbox" id={i} data-switch="success" checked={item.isRedeemed} onClick={() => this.setRewardRedeemed(item, i)} readOnly disabled={!(item.rewardSent || item.name)}/>
                                                                <label htmlFor={i} data-on-label="Yes" data-off-label="No"/>
                                                            </td>
                                                        }
                                                    </tr>
                                                }, this)
                                            }
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                            </div>
                            <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6" style={{textAlign: "center"}}>
                                            <h4 style={{color: 'black'}}>Rewards</h4>
                                            <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                                <li className="nav-item" onClick={() => {
                                                    this.setState({showIndividualRewards: true});
                                                    document.getElementById("showIndividualRewards").classList.add('active');
                                                    document.getElementById("showGroupRewards").classList.remove('active');
                                                }}>
                                                    <a href="#" data-toggle="tab" aria-expanded="false"
                                                       className="nav-link active" id="showIndividualRewards"
                                                       style={{backgroundColor: '#fafbfe'}}>
                                                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                                        <span className="d-none d-sm-inline">Individual</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item" onClick={() => {
                                                    this.setState({showIndividualRewards: false});
                                                    document.getElementById("showIndividualRewards").classList.remove('active');
                                                    document.getElementById("showGroupRewards").classList.add('active');
                                                }}>
                                                    <a href="#" data-toggle="tab" aria-expanded="true"
                                                       className="nav-link" id="showGroupRewards"
                                                       style={{backgroundColor: '#fafbfe'}}>
                                                        <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                                        <span className="d-none d-lg-block">Group</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            {rewardsList.length > 0 ?
                                                rewardsList.map(function (item, index) {
                                                    return (
                                                        <div key={index} className="row"
                                                             style={{
                                                                 fontSize: 20,
                                                                 paddingTop: 10,
                                                                 paddingBottom: 10,
                                                                 textAlign: 'left',
                                                                 color: 'black',
                                                                 border: 'solid 1px #d3d3d3',
                                                                 borderRadius: 5
                                                             }}>
                                                            <div className="col-md-6">
                                                                {item.rewardName}
                                                            </div>
                                                            <div className="col-md-6" style={{textAlign: "right"}}>
                                                                Amount: {item.amount}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{textAlign: 'left', color: 'black'}}>
                                                    <p>No Rewards Added</p>
                                                </div>
                                            }
                                        </div>
                                        {selectedGame &&
                                            <div className="col-md-6 justify-content-center"
                                                 style={{textAlign: "center", backgroundColor: "#FAFBFE"}}>
                                                <h4 style={{color: 'black'}}>Bingo Squares</h4>
                                                <input style={{minWidth: 220}} type="text" id="filterBingoSquares"
                                                       name="filterBingoSquares"
                                                       placeholder="Filter By Bingo Square Name" onChange={(evt) => {
                                                    this.setState({
                                                        filterBingoSquares: evt.target.value,
                                                        selectedItem: 0
                                                    })
                                                }}/>
                                                <Carousel style={{backgroundColor: "#FAFBFE"}} showArrows={true}
                                                          showStatus={false} showIndicators={false} showThumbs={false}
                                                          selectedItem={this.state.selectedItem} onChange={(e) => {
                                                    this.setState({
                                                        selectedItem: e,
                                                    });
                                                }}>
                                                    {
                                                        totalSquares.filter(object => object.name.toLowerCase().includes(this.state.filterBingoSquares.toLowerCase())).map(function (item, i) {
                                                            return <div style={{backgroundColor: "#FAFBFE", padding: 10}}
                                                                        key={i}>
                                                                <p style={{color: 'black'}}>{i + 1}/{totalSquares.filter(object => object.name.toLowerCase().includes(this.state.filterBingoSquares.toLowerCase())).length.toString()}</p>
                                                                <p style={{color: 'black'}}>{item.name}</p>
                                                                {item.adminOnly &&
                                                                    <p style={{color: 'red'}}>Admin Only</p>
                                                                }
                                                                {item.defaultCenter &&
                                                                    <p style={{color: 'red'}}>DEFAULT CENTER FAN SIDE</p>
                                                                }
                                                                <div style={{marginBottom: 10}}>
                                                                    <img src={item.image} alt=""
                                                                         style={{maxWidth: 100, maxHeight: 100}}/>
                                                                </div>
                                                                {!item.completed ?
                                                                    <button className="btn btn-danger"
                                                                            onClick={() => this.markCompleted(item)}>Mark
                                                                        Completed
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-success disabled">Completed</button>
                                                                }
                                                            </div>
                                                        }, this)
                                                    }
                                                </Carousel>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{display: this.state.showGames ? 'block' : 'none', marginLeft: 10}}>
                                <p style={{
                                    color: 'black',
                                    marginLeft: 20,
                                    fontSize: 20,
                                    marginTop: 20
                                }}>Future Games</p>
                                <table className="table table-striped" style={{color: 'black'}}>
                                    <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Make Current Game</th>
                                        <th>Delete Game</th>
                                    </tr>
                                    {
                                        futureGamesList.map(function (item, i) {
                                            let dateTime = convertTimeStampToHumanReadable(item.scheduleInfo.performAt);
                                            let endTime = convertTimeStampToHumanReadable(item.scheduleInfo.endAt);
                                            return <tr key={i}>
                                                <td style={{fontFamily: 'Open Sans'}}>{item.gameName}</td>
                                                <td style={{fontFamily: 'Open Sans'}}>{dateTime}</td>
                                                <td style={{fontFamily: 'Open Sans'}}>{endTime}</td>
                                                <td style={{fontFamily: 'Open Sans'}}><button className="btn btn-primary"
                                                                                              onClick={() => this.makeFutureGameCurrent(item)}>MAKE
                                                    CURRENT
                                                </button></td>
                                                <td style={{fontFamily: 'Open Sans'}}><button className="btn btn-danger"
                                                                                              onClick={() => this.removeGameFromFutureList(item.id)}>DELETE</button>
                                                </td>
                                            </tr>
                                        }, this)
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3"> Create Game</h4>
                            <div id="rootwizard">
                                <ul className="nav nav-pills bg-dark-light nav-justified mb-3">
                                    <li className="nav-item"
                                        onClick={() => this.toggleWizardLocation('first', 'second', 'third', 'fourth', 'fifth')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                            <span className="fa fa-pencil-square-o"/>
                                            <span className="d-none d-sm-inline"> The Basics</span>
                                        </a>
                                    </li>

                                    <li className="nav-item"
                                        onClick={() => this.toggleWizardLocation('second', 'first', 'third', 'fourth', 'fifth')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                            <span className="fa fa-square"/>
                                            <span className="d-none d-sm-inline"> Squares</span>
                                        </a>
                                    </li>
                                    <li className="nav-item"
                                        onClick={() => this.toggleWizardLocation('third', 'first', 'second', 'fourth', 'fifth')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                            <span className="fa fa-tv"/>
                                            <span className="d-none d-sm-inline"> Scoreboard</span>
                                        </a>
                                    </li>
                                    <li className="nav-item"
                                        onClick={() => this.toggleWizardLocation('fourth', 'first', 'second', 'third', 'fifth')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="fourth1">
                                            <span className="fa fa-trophy"/>
                                            <span className="d-none d-sm-inline"> Add Prizes</span>
                                        </a>
                                    </li>
                                    <li className="nav-item"
                                        onClick={() => this.toggleWizardLocation('fifth', 'first', 'second', 'third', 'fourth')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="fifth1">
                                            <span className="fa fa-sign-out"/>
                                            <span className="d-none d-sm-inline"> Finish</span>
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content mb-0 b-0" style={{fontFamily: 'Roboto'}}>
                                    <div className="tab-pane active show" id="first">
                                        <form id="accountForm" method="post" action="#" className="form-horizontal">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label"
                                                               htmlFor="gameName"> Game Name</label>
                                                        <div className="col-md-9">
                                                            <input id="gameName" name="gameName" type="text"
                                                                   className="form-control" value={this.state.gameName}
                                                                   onChange={this.handleChange}
                                                                   placeholder="12/11 vs MonStars"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label"
                                                               htmlFor="startTime"> Set Start Time</label>
                                                        <div className="col-md-9">
                                                            <DatePicker showTimeInput dateFormat="Pp"
                                                                        selected={this.state.startTime}
                                                                        onChange={date => this.setState({startTime: date})}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label"
                                                               htmlFor="endTime"> Set End Time</label>
                                                        <div className="col-md-9">
                                                            <DatePicker showTimeInput dateFormat="Pp"
                                                                        selected={this.state.endTime}
                                                                        onChange={date => this.setState({endTime: date})}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane" id="second">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row mb-3">
                                                    <label className="col-md-3 col-form-label"
                                                           htmlFor="surname3"> Card Size (Must be a square)</label>
                                                    <div className="col-md-9">
                                                        <input id="bingoCardSize" name="bingoCardSize" type="number"
                                                               className="form-control" value={this.state.bingoCardSize}
                                                               onChange={this.handleChange} placeholder="9"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group" align="center">
                                                    <label htmlFor="selected_bingo_square"
                                                           style={{width: '100%'}}>Add Squares <button
                                                        className="btn btn-info"
                                                        onClick={() => this.addAllSquaresToGame()}>ADD ALL SQUARES</button></label>
                                                    <select className="form-control" name="selected_bingo_square" id="selected_bingo_square" value={this.state.selected_bingo_square} onChange={this.handleChange}>
                                                        <option/>
                                                        {
                                                            itemsList.map(function (item, index) {
                                                                return (
                                                                    <option value={item.id} key={index}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {this.state.bingoCardSize && this.state.bingoCardSize % 2 !== 0 && this.state.bingoCardSize > 8 &&
                                                        <div className="form-check" style={{marginBottom: 20}} align="left">
                                                            <input value={this.state.setToCurrentDefault}
                                                                   className="form-check-input" id="setToCurrentDefault"
                                                                   name="setToCurrentDefault" type="checkbox"
                                                                   checked={this.state.setToCurrentDefault}
                                                                   onChange={this.handleChange}/>
                                                            <label className="form-check-label" htmlFor="setToCurrentDefault">Make
                                                                Center Square</label>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3" align="center" style={{marginTop: 10}}>
                                            <div className="col-md-12">
                                                <button className="btn btn-primary btn-admin"
                                                        onClick={() => this.addItemToLocalArray()}>Add
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginBottom: 20}}>
                                            <div className="col-12" align="center">
                                                <ol style={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    flexWrap: "wrap",
                                                    flexDirection: "row",
                                                    columnGap: 25
                                                }}>
                                                    {
                                                        itemsAdded.map(function (item, index) {
                                                            return (
                                                                <li style={{display: 'flex', alignItems: 'center'}}
                                                                    key={index}>
                                                                    {item.name}
                                                                    {item.defaultCenter &&
                                                                        <span>, DEFAULT CENTER</span>
                                                                    }
                                                                    <span style={{
                                                                        float: 'right',
                                                                        marginLeft: 10
                                                                    }} className="fa fa-trash-o"
                                                                          onClick={() => vm.removeFromToAddArray(index, "itemsAdded")}/>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="third">
                                        <div className="form-check" style={{marginBottom: 20}}>
                                            <input value={this.state.activateScoreboard}
                                                   className="form-check-input" id="activateScoreboard"
                                                   name="activateScoreboard" type="checkbox"
                                                   checked={this.state.activateScoreboard} onChange={this.handleChange}/>
                                            <label className="form-check-label" htmlFor="codes">Create Admin Board?</label>
                                        </div>
                                        {this.state.activateScoreboard &&
                                            <div>
                                                {itemsList && itemsList.length > 0 ?
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group" align="center">
                                                                    <label htmlFor="selected_admin_bingo_square"
                                                                           style={{width: '100%'}}>Add Admin
                                                                        Squares</label>
                                                                    <select className="form-control"
                                                                            name="selected_admin_bingo_square"
                                                                            id="selected_admin_bingo_square"
                                                                            value={this.state.selected_admin_bingo_square}
                                                                            onChange={this.handleChange}>
                                                                        <option/>
                                                                        {
                                                                            itemsList.map(function (item, index) {
                                                                                return (
                                                                                    <option value={item.key}
                                                                                            key={index}>
                                                                                        {item.name}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mb-3" align="center"
                                                             style={{marginTop: 10}}>
                                                            <div className="col-md-12">
                                                                <button className="btn btn-primary btn-admin"
                                                                        onClick={() => this.addItemToLocalArray(true)}>Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div style={{textAlign: 'center'}}>
                                                        Add some squares to the game before you can create this board!
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-12" align="center">
                                                        {itemsScoreboardAdded.length > 0 &&
                                                            <>
                                                                <p>This is how your scorecard will appear on the
                                                                    scoreboard</p>
                                                                {Math.sqrt(itemsScoreboardAdded.length) % 1 !== 0 &&
                                                                    <div style={{color: "red"}}>
                                                                        Not a square, add more or take away some squares.
                                                                    </div>
                                                                }
                                                                <div style={{
                                                                    width: 100,
                                                                    outline: "1px black solid",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    flexWrap: "wrap",
                                                                    margin: "auto",
                                                                    justifyContent: "center"
                                                                }}>
                                                                    {
                                                                        itemsScoreboardAdded.map(function (item, index) {
                                                                            return (
                                                                                <div key={index}
                                                                                     style={{
                                                                                         width: sizeOfBingoSquares,
                                                                                         height: sizeOfBingoSquares,
                                                                                         margin: 5
                                                                                     }}>
                                                                                    <span
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            display: "",
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => this.removeFromToAddArray(index, 'itemsScoreboardAdded')}>❌</span>
                                                                                    <img width={sizeOfBingoSquares}
                                                                                         height={sizeOfBingoSquares}
                                                                                         src={item.image} alt=""/>
                                                                                </div>)
                                                                        }, this)
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="tab-pane" id="fourth">
                                        {this.state.activateScoreboard &&
                                            <ul className="nav nav-pills bg-dark-light nav-justified mb-3">
                                                <li className="nav-item" onClick={() => {
                                                    this.setState({showPersonalPrizes: true});
                                                    document.getElementById('showBingoPrizes').classList.remove('active');
                                                    document.getElementById('showPersonalPrizes').classList.add('active');
                                                }}>
                                                    <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show"
                                                       id="showPersonalPrizes">
                                                        <span className="fa fa-user"/>
                                                        <span className="d-none d-sm-inline"> Personal</span>
                                                    </a>
                                                </li>

                                                <li className="nav-item" onClick={() => {
                                                    this.setState({showPersonalPrizes: false});
                                                    document.getElementById('showPersonalPrizes').classList.remove('active');
                                                    document.getElementById('showBingoPrizes').classList.add('active');
                                                }}>
                                                    <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="showBingoPrizes">
                                                        <span className="fa fa-users"/>
                                                        <span className="d-none d-sm-inline"> Group</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        }
                                        <div className="form-horizontal">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                                        <div className="col-md-9">
                                                            <select className="form-control" onChange={(evt) => this.setState({rewardToAdd: evt.target.value})}>
                                                                <option/>
                                                                {
                                                                    this.state.ticketList.map(function (item, index) {
                                                                        return (
                                                                            <option value={item.id} key={index}>{item.rewardName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                                        <div className="col-md-9">
                                                            <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5"/>
                                                        </div>
                                                    </div>

                                                    <div className="form-check">
                                                        <input value={this.state.codes} className="form-check-input" id="codes" name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange}/>
                                                        <label className="form-check-label" htmlFor="codes">Redeem Codes?</label>
                                                    </div>

                                                    <div className="form-check" style={{display: this.state.codes ? 'flex' : 'none', marginTop: 5, marginBottom: 5}}>
                                                        <input value={this.state.qrCodes} className="form-check-input" id="qrCodes" name="qrCodes" type="checkbox" checked={this.state.qrCodes} onChange={this.handleChange}/>
                                                        <label className="form-check-label" htmlFor="codes">Convert Codes To QRCode?</label>
                                                    </div>

                                                    <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none'}}>
                                                        <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                                        <div className="col-md-9">
                                                            <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row mb-3" align="center">
                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary btn-admin" onClick={() => this.addRewardToLocalArray()}>Add Reward</button>
                                                        </div>
                                                    </div>

                                                    {rewardsAdded && rewardsAdded.length > 1 &&
                                                        <div className="form-check">
                                                            <input className="form-check-input" id={randomOrderName} name={randomOrderName} type="checkbox" checked={randomOrder} onChange={this.handleChange}/>
                                                            <label className="form-check-label" htmlFor={randomOrderName}>Give Out In Random Order</label>
                                                        </div>
                                                    }

                                                    <div className="form-group mb-3">
                                                        <ol style={{listStylePosition: 'inside', textAlign: 'left'}} className="offset-md-0 col-md-10">
                                                            {
                                                                rewardsAdded.map(function (item, index) {
                                                                    let codesText = "No";
                                                                    if (item.codes) {
                                                                        codesText = "Yes"
                                                                    }
                                                                    return (
                                                                        <div key={index} className="form-group">
                                                                            <li style={{display: 'flex', alignItems: 'center'}}>{index + 1}) {item.amount} - {item.reward.rewardName}; Codes: {codesText} <span style={{float: 'right', marginLeft: 10}} className="fa fa-trash-o" onClick={() => vm.removeFromToAddArray(index, vm.state.showPersonalPrizes ? "rewardsAdded" : "rewardsScoreboardAdded")}/></li>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </ol>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="fifth">
                                        <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <h2 className="mt-0">
                                                        <i className="mdi mdi-check-all"/>
                                                    </h2>
                                                    <h3 className="mt-0">Finish</h3>

                                                    <div className="row form-group">
                                                        <div className="col-md-12">
                                                            <p>You are all set to create a game! Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <ul className="list-inline wizard mb-0">
                                        <li className="previous list-inline-item"
                                            style={{display: this.state.wizardLocation === 'first' ? 'none' : ''}}><a
                                            href="#" className="btn btn-primary btn-lg previous-wizard-button"
                                            onClick={() => this.navButtonClicked('prev')}>Previous</a></li>
                                        <li className="next list-inline-item float-end"><a href="#"
                                                                                           className="btn btn-primary btn-lg next-wizard-button"
                                                                                           onClick={() => this.navButtonClicked('next')}
                                                                                           style={{display: this.state.wizardLocation === 'fifth' ? 'none' : ''}}>Next</a>
                                        </li>
                                        <li className="next list-inline-item float-end"><a href="#"
                                                                                           className="btn btn-primary btn-lg creategame-wizard-button"
                                                                                           onClick={() => this.createGame()}
                                                                                           style={{display: this.state.wizardLocation === 'fifth' ? '' : 'none'}}>Create
                                            Game</a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.markLoading} id="myModal2">
                    <div className="card" style={{textAlign: "center", minHeight: 200}}>
                        <div className="card-body"
                             style={{
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 flexDirection: "column"
                             }}>
                            <h4 className="header-title mb-3" style={{margin: 20}}> Loading Marking</h4>
                            <LoadingSpinner color='#000' loading={true}/>
                            <p style={{color: "black", marginTop: 20}}>{this.state.loadingMessage}</p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default SetUpGame;
