import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { app } from '../../base';
import logoImage from '../../styles/images/sqwad-hand.png';
import logoWithTextImageBlack from '../../styles/images/new_sqwad_logo.png';
import '../../styles/css/main.css';
import Swal from 'sweetalert2';

class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.auth = getAuth(app);
        this.authWithEmailPassword = this.authWithEmailPassword.bind(this);
        this.state = {
            loading: true,
            redirect: false,
        };
    }

    authWithEmailPassword(event) {
        event.preventDefault();
        const email = this.emailInput.value;
        const password = this.passwordInput.value;
        this.setState({ loading: true });

        fetchSignInMethodsForEmail(this.auth, email)
            .then((providers) => {
                this.setState({ loading: false });
                return signInWithEmailAndPassword(this.auth, email, password);
            })
            .then((userCredential) => {
                const user = userCredential.user;
                if (user) {
                    this.loginForm.reset();
                    this.props.setCurrentUser(user);
                    this.setState({
                        redirect: true,
                    });
                } else {
                    Swal.fire({
                        title: 'Wrong credentials',
                        text: 'Check your email and password and try again!',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                if (error.code === 'auth/user-not-found') {
                    Swal.fire({
                        title: 'Uh oh!',
                        text: 'User not found, is the email correct?',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                } else if (error.code === 'auth/wrong-password') {
                    Swal.fire({
                        title: 'Uh oh!',
                        text: 'Password incorrect!',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                } else {
                    Swal.fire({
                        title: 'Uh oh!',
                        text: error.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                }
            });
    }

    componentDidMount() {
        if (this.auth.currentUser) {
            this.setState({ redirect: true });
        }
        this.setState({
            loading: false,
        });
    }

    render() {
        if (this.state.redirect === true || this.props.user) {
            return <Navigate to="/admin" />;
        }
        return (
            <div className="auth-fluid">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <div className="auth-fluid-form-box">
                    <div className="align-items-center d-flex h-100">
                        <div className="card-body">

                            <div className="auth-brand text-center text-lg-left" style={{ marginBottom: '50px' }}>
                                <img src={logoWithTextImageBlack} alt="" height="auto" width="200px" />
                            </div>

                            <h4 className="mt-0" style={{ fontWeight: 'bolder', fontFamily: 'Roboto' }}>Welcome</h4>
                            <p className="text-muted2 mb-4" style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>Enter your email address and password to access account.</p>

                            <form onSubmit={this.authWithEmailPassword} ref={(form) => { this.loginForm = form }} style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>
                                <div className="mb-3">
                                    <label htmlFor="emailaddress">Email address</label>
                                    <input className="form-control" type="email" name="email" ref={(input) => { this.emailInput = input }} placeholder="Enter your email" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password">Password</label>
                                    <input className="form-control" name="password" type="password" ref={(input) => { this.passwordInput = input }} placeholder="Enter your password" />
                                </div>
                                <div className="input-group">
                                    <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: "#ff1f3e", borderColor: "#ff1f3e", fontWeight: 'bold', fontFamily: 'Roboto' }}>Log In </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <div className="auth-fluid-right text-center">
                    <div className="auth-user-testimonial">
                        <h2 className="mb-3" style={{ fontWeight: 'bolder', fontFamily: 'Roboto' }}>Tip Of The Month</h2>
                        <p className="lead" style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>Plug the emails collected into Facebook, Instagram, & Snapchat ads for ticketing retargeting (5x-8x returns)
                        </p>
                        <img src={logoImage} width="200px" alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminLogin;
