import React, { Component } from 'react';
import '../../styles/css/SideMenu.css';
import '../../styles/css/AdminMain.css';
import { database } from '../../base';
import { ref, onValue } from 'firebase/database';
import logoWithTextImage from '../../styles/images/sqwad_logo_white.svg';
import {getAuth, signOut} from "firebase/auth";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantVariables: {}
    };
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    const tenantVariablesRef = ref(database, 'tenantVariables');
    onValue(tenantVariablesRef, (snapshot) => {
      const tenantVariables = snapshot.val() || {};
      this.setState({ tenantVariables });
    });
  }

  logOut() {
    const auth = getAuth();
    signOut(auth).then(() => {
      this.setState({ redirect: true });
    }).catch((error) => {
      // Handle errors here
      console.error("Sign out error", error);
    });
  }

  render() {
    const variables = this.state.tenantVariables || {};
    const homeLink = "/admin";
    const scratcherGameLink = "/setupgame";
    const ticketsLink = "/setuptickets";
    const teamVariables = "/setupteamvariables";
    const rulesAndRegs = "/setuprulesandregs";
    const ticketEmail = "/setupticketemail";
    const loginVariables = "/setuploginvariables";
    const matchesLink = "/setupbingosquares";
    const webHooksLink = "/webhooks";
    const apiLink = "/apimanagement";
    const statFeedLink = "/statfeedlink";
    let doNotCollectEmail = variables.doNotCollectEmail;
    const isWebHooksEnabled = process.env.REACT_APP_WEB_HOOKS_ON === "true";
    const isApiEnabled = process.env.REACT_APP_ENABLE_API === "true";
    const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
    const isStatFeedEnabled = process.env.REACT_APP_STAT_FEED !== null;

    return (
        <div className="admin-sidebar">
          <div className="logo">
            <a href={homeLink}>
              <div className="logo-img">
                <img src={logoWithTextImage} height="30" alt="" />
              </div>
            </a>
          </div>
          <div className="nav-container">
            <ul className="nav">
              <li className="side-menu-items">
                <a href={homeLink}>
                  <span className="fa fa-home pre_side_item" />
                  <span>Dashboard</span>
                  <span className="fa fa-chevron-right after_fa_side" />
                </a>
              </li>
              <li>
                <a href={scratcherGameLink}>
                  <span className="fa fa-gamepad pre_side_item" />
                  <span>Games</span>
                  <span className="fa fa-chevron-right after_fa_side" />
                </a>
              </li>
              <li>
                <a href={matchesLink}>
                  <span className="fa fa-clone pre_side_item" />
                  <span>Bingo Cards</span>
                  <span className="fa fa-chevron-right after_fa_side" />
                </a>
              </li>
              <li>
                <a href={ticketsLink}>
                  <span className="fa fa-trophy pre_side_item" />
                  <span>Prizes</span>
                  <span className="fa fa-chevron-right after_fa_side" />
                </a>
              </li>
              <li>
                <a href={teamVariables}>
                  <span className="fa fa-pencil-square-o pre_side_item" />
                  <span>Game Branding</span>
                  <span className="fa fa-chevron-right after_fa_side" />
                </a>
              </li>
              <li>
                <a href={rulesAndRegs}>
                  <span className="fa fa-legal pre_side_item" />
                  <span>Rules And Regs</span>
                  <span className="fa fa-chevron-right after_fa_side" />
                </a>
              </li>
              {isMlbApp &&
                  <li>
                    <a href={ticketEmail}>
                      <span className="fa fa-users pre_side_item" />
                      <span className="text">MLB APP Message</span>
                      <span className="fa fa-chevron-right after_fa_side" />
                    </a>
                  </li>
              }
              <li style={{ display: doNotCollectEmail || isMlbApp ? "none" : "" }}>
                <a href={ticketEmail}>
                  <span className="fa fa-envelope-open-o pre_side_item" />
                  <span className="text">Email Branding</span>
                  <span className="fa fa-chevron-right after_fa_side" />
                </a>
              </li>
              {!isMlbApp &&
                  <li>
                    <a href={loginVariables}>
                      <span className="fa fa-users pre_side_item" />
                      <span className="text">Fan Login</span>
                      <span className="fa fa-chevron-right after_fa_side" />
                    </a>
                  </li>
              }
              {isWebHooksEnabled &&
                  <li>
                    <a href={webHooksLink}>
                      <span className="fa fa-plug pre_side_item" />
                      <span className="text">Web Hooks</span>
                      <span className="fa fa-chevron-right after_fa_side" />
                    </a>
                  </li>
              }
              {isApiEnabled &&
                  <li>
                    <a href={apiLink}>
                      <span className="fa fa-bolt pre_side_item" />
                      <span className="text">API</span>
                      <span className="fa fa-chevron-right after_fa_side" />
                    </a>
                  </li>
              }
              {/*{isStatFeedEnabled &&*/}
              {/*    <li>*/}
              {/*      <a href={statFeedLink}>*/}
              {/*        <span className="fa fa-bolt pre_side_item"/>*/}
              {/*        <span className="text">Stat Feed</span>*/}
              {/*        <span className="fa fa-chevron-right after_fa_side"/>*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*}*/}
              <div className="bottom-buttons">
                <li onClick={this.logOut}>
                  <a href={homeLink}>
                    <span className="fa fa-sign-out pre_side_item" />
                    <span className="text">Logout</span>
                    <span className="fa fa-chevron-right after_fa_side" />
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
    );
  }
}

export default SideMenu;
