import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { database } from '../../base'; // Adjust the import to point to your firebase configuration file
import { ref, onValue, set } from 'firebase/database';
import '../../styles/css/AdminMain.css';
import { ErrorMessage, Toast } from '../utils/HelpfulFunction';
import { Modal } from 'reactstrap';
import { PhotoshopPicker } from 'react-color';
import RichTextEditor from 'html-verify-react-rte';

class SetUpBrandingVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOne: true,
            showTabTwo: false,
            showTabThree: false,
            loading: true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            showTabOnegameTextToggle: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        const tenantVariablesRef = ref(database, 'tenantVariables');
        onValue(tenantVariablesRef, (snapshot) => {
            const tenantVariables = snapshot.val() || {};
            this.setState({
                tenantVariables,
                loading: false
            });
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let variables = this.state.tenantVariables || {};
        let winningMessage = variables['winningMessage'] || RichTextEditor.createEmptyValue();
        winningMessage = winningMessage.toString('html').replace(/<a /g, '<a rel="noopener noreferrer" ');
        if (winningMessage === "<p><br></p>") {
            winningMessage = "";
        }
        let losingMessage = variables['losingMessage'] || RichTextEditor.createEmptyValue();
        losingMessage = losingMessage.toString('html').replace(/<a /g, '<a rel="noopener noreferrer" ');
        if (losingMessage === "<p><br></p>") {
            losingMessage = "";
        }
        let checkedBingoMessage = variables['checkedBingoMessage'] || RichTextEditor.createEmptyValue();
        checkedBingoMessage = checkedBingoMessage.toString('html').replace(/<a /g, '<a rel="noopener noreferrer" ');
        if (checkedBingoMessage === "<p><br></p>") {
            checkedBingoMessage = "";
        }
        let toLateToGetBoardBody = variables['toLateToGetBoardBody'] || RichTextEditor.createEmptyValue();
        toLateToGetBoardBody = toLateToGetBoardBody.toString('html').replace(/<a /g, '<a rel="noopener noreferrer" ');
        if (toLateToGetBoardBody === "<p><br></p>") {
            toLateToGetBoardBody = "";
        }
        variables['losingMessage'] = losingMessage;
        variables['winningMessage'] = winningMessage;
        variables['checkedBingoMessage'] = checkedBingoMessage;
        variables['toLateToGetBoardBody'] = toLateToGetBoardBody;
        this.setState({ loading: true });

        const tenantVariablesRef = ref(database, 'tenantVariables');
        set(tenantVariablesRef, variables)
            .then(() => {
                this.setState({ loading: false });
                Toast.fire({
                    title: 'Tenant Variables Updated!'
                });
            })
            .catch((err) => {
                this.setState({ loading: false });
                ErrorMessage.fire({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in'
                });
                console.log(err);
            });
    }

    handleChange(evt) {
        let tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[evt.target.name] = evt.target.value;
        this.setState({ tenantVariables: tenantVariablesCopy });
    }

    handleImageChange(event) {
        let name_of_file = event.target.name;
        let target = this[name_of_file];
        let file_to_update = target.files[0];
        this.setState({ loading: true });
        this.UploadImage.upload_file(file_to_update).then(res => {
            this.setState({ loading: false });
            if (res.error) {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: res.error
                });
            } else if (res.imageUrl) {
                const tenantVariablesCopy = this.state.tenantVariables;
                tenantVariablesCopy[name_of_file] = res.imageUrl;
                this.setState({
                    tenantVariables: tenantVariablesCopy,
                }, () => {
                    this[name_of_file].value = "";
                });
            } else {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: 'There was some issue with the image upload, please check the image type and size and try again'
                });
            }
        });
    }

    handleRichTextChange = (name, text) => {
        let tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[name] = text;
        this.setState({ tenantVariables: tenantVariablesCopy });
    }

    switchTextShowing(element) {
        if (element === "first") {
            document.getElementById('showfirst').classList.add('active');
            document.getElementById('showsecond').classList.remove('active');
            document.getElementById('showthird').classList.remove('active');
            this.setState({
                showTabOne: true,
                showTabTwo: false,
                showTabThree: false,
            });
        } else if (element === "second") {
            document.getElementById('showfirst').classList.remove('active');
            document.getElementById('showsecond').classList.add('active');
            document.getElementById('showthird').classList.remove('active');
            this.setState({
                showTabOne: false,
                showTabTwo: true,
                showTabThree: false,
            });
        } else {
            document.getElementById('showfirst').classList.remove('active');
            document.getElementById('showsecond').classList.remove('active');
            document.getElementById('showthird').classList.add('active');
            this.setState({
                showTabOne: false,
                showTabTwo: false,
                showTabThree: true,
            });
        }
    }

    openColorPicker(colorToChange) {
        let oldColor = this.state.tenantVariables[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        });
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        });
    };

    acceptPicker() {
        let colorToChange = this.state.colorToUpdate;
        let tenantVariables = this.state.tenantVariables;
        tenantVariables[colorToChange] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariables,
            updateHappend: true,
            colorPickerShow: false,
        });
    }

    dismissPicker() {
        this.setState({
            colorPickerShow: false,
        });
    }

    toggleColors() {
        if (this.state.colorsTab) {
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({ colorsTab: !this.state.colorsTab });
    }

    tripleToggleControl(element, whichToggle) {
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        const firstElement = document.getElementById('showfirst' + whichToggle);
        const secondElement = document.getElementById('showsecond' + whichToggle);
        const thirdElement = document.getElementById('showthird' + whichToggle);
        if (element === "first") {
            firstElement.classList.add('active');
            secondElement.classList.remove('active');
            if (thirdElement) {
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            });
        } else if (element === "second") {
            firstElement.classList.remove('active');
            secondElement.classList.add('active');
            if (thirdElement) {
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            });
        } else {
            firstElement.classList.remove('active');
            secondElement.classList.remove('active');
            if (thirdElement) {
                thirdElement.classList.add('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            });
        }
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const topHeaderImage = tenantVariables.topHeaderImage;
        const backgroundImage = tenantVariables.backgroundImage;
        const backgroundScoreboardImage = tenantVariables.backgroundScoreboardImage;
        const sideScoreboardImage = tenantVariables.sideScoreboardImage;
        const frontImage = tenantVariables.frontLogoImage;
        const filledInIcon = tenantVariables.filledInIcon;
        let winningMessage = tenantVariables.winningMessage || RichTextEditor.createEmptyValue();
        if (typeof winningMessage === 'string') {
            winningMessage = RichTextEditor.createValueFromString(winningMessage, 'html');
        }
        let losingMessage = tenantVariables.losingMessage || RichTextEditor.createEmptyValue();
        if (typeof losingMessage === 'string') {
            losingMessage = RichTextEditor.createValueFromString(losingMessage, 'html');
        }
        let checkedBingoMessage = tenantVariables.checkedBingoMessage || RichTextEditor.createEmptyValue();
        if (typeof checkedBingoMessage === 'string') {
            checkedBingoMessage = RichTextEditor.createValueFromString(checkedBingoMessage, 'html');
        }
        let toLateToGetBoardBody = tenantVariables.toLateToGetBoardBody || RichTextEditor.createEmptyValue();
        if (typeof toLateToGetBoardBody === 'string') {
            toLateToGetBoardBody = RichTextEditor.createValueFromString(toLateToGetBoardBody, 'html');
        }
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        if (!isMlbApp) {
            toolbarConfig.display.push('LINK_BUTTONS');
        }
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0, marginTop: '5px' }}>Game Branding</p>
                    <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                    <div className="container-out">
                        <div className="admin-form-box" style={{ marginTop: '0px', paddingTop: '5px' }}>
                            <form onSubmit={this.handleSubmit} id="create-game-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: '20px' }}><span className="fa fa-arrow-circle-o-up" /> Update</button>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                    <li className="nav-item" onClick={() => this.switchTextShowing('first')}>
                                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirst">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Fan Color/Images</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.switchTextShowing('second')}>
                                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link" id="showsecond">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Scoreboard</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.switchTextShowing('third')}>
                                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showthird">
                                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Game Text</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{ display: this.state.showTabOne === true ? '' : 'none' }}>
                                    <li className="nav-item" onClick={() => this.toggleColors()}>
                                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showColors">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Colors</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.toggleColors()}>
                                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showImages">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Images</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{ display: this.state.showTabThree === true ? '' : 'none' }}>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl("first", "gameTextToggle")}>
                                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirstgameTextToggle">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Winning/Losing Text</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl("second", "gameTextToggle")}>
                                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showsecondgameTextToggle">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Actions Text</span>
                                        </a>
                                    </li>
                                </ul>
                                <div style={{ display: this.state.showTabOne ? 'block' : 'none' }}>
                                    <div style={{ display: this.state.colorsTab ? 'block' : 'none' }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Button Colors</h4>
                                                <div className="form-inline">
                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("primaryColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="primaryColor" style={{ marginRight: 10 }}>Button Color</label>
                                                    <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("secondaryColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="secondaryColor" style={{ marginRight: 10 }}>Button Text Color</label>
                                                    <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Logout Color</h4>
                                                <div className="form-inline">
                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("logOutButtonColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="logOutButtonColor" style={{ marginRight: 10 }}>Button Color</label>
                                                    <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.colorsTab ? 'none' : 'block' }}>
                                        <div className="admin-grid-container three-columns" style={{ float: 'left', display: 'flex', alignItems: 'flex-end' }}>
                                            <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                                <div style={{ cursor: "pointer", display: topHeaderImage ? "" : "none", textAlign: "right" }} onClick={() => this.setState({ tenantVariables: { ...tenantVariables, topHeaderImage: null } })}>
                                                    ❌
                                                </div>
                                                <img src={topHeaderImage} width="160" height="auto" alt="" />
                                                <br />
                                                <label htmlFor="topHeaderImage">Header Image</label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="topHeaderImage" name="topHeaderImage" type="file" ref={input => { this.topHeaderImage = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topHeaderImage').click()} />
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                                <div style={{ cursor: "pointer", display: backgroundImage ? "" : "none", textAlign: "right" }} onClick={() => this.setState({ tenantVariables: { ...tenantVariables, backgroundImage: null } })}>
                                                    ❌
                                                </div>
                                                <img src={backgroundImage} width="160" height="auto" alt="" />
                                                <br />
                                                <label htmlFor="backgroundImage">Phone Background Image (1600px X 950px)</label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="backgroundImage" name="backgroundImage" type="file" ref={input => { this.backgroundImage = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                                <div style={{ cursor: "pointer", display: frontImage ? "" : "none", textAlign: "right" }} onClick={() => this.setState({ tenantVariables: { ...tenantVariables, frontImage: null } })}>
                                                    ❌
                                                </div>
                                                <img src={frontImage} width="150" height="auto" alt="" />
                                                <br />
                                                <label htmlFor="frontLogoImage">Front Logo (845px X 700px)</label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => { this.frontLogoImage = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                                <div style={{ cursor: "pointer", display: filledInIcon ? "" : "none", textAlign: "right" }} onClick={() => this.setState({ tenantVariables: { ...tenantVariables, filledInIcon: null } })}>
                                                    ❌
                                                </div>
                                                <img src={filledInIcon} width="150" height="auto" alt="" />
                                                <br />
                                                <label htmlFor="filledInIcon">Filled In Icon<br /></label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="filledInIcon" name="filledInIcon" type="file" ref={input => { this.filledInIcon = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('filledInIcon').click()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: this.state.showTabTwo ? 'block' : 'none' }}>
                                    <div className="admin-grid-container three-columns" style={{ float: 'left', display: 'flex', alignItems: 'flex-end' }}>
                                        <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                            <div style={{ cursor: "pointer", display: backgroundScoreboardImage ? "" : "none", textAlign: "right" }} onClick={() => this.setState({ tenantVariables: { ...tenantVariables, backgroundScoreboardImage: null } })}>
                                                ❌
                                            </div>
                                            <img src={backgroundScoreboardImage} width="160" height="auto" alt="" />
                                            <br />
                                            <label htmlFor="backgroundScoreboardImage">Background Image (1600px X 950px)</label>
                                            <div className="form-group">
                                                <input style={{ display: 'none' }} id="backgroundScoreboardImage" name="backgroundScoreboardImage" type="file" ref={input => { this.backgroundScoreboardImage = input; }} onChange={this.handleImageChange} />
                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundScoreboardImage').click()} />
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                            <div style={{ cursor: "pointer", display: sideScoreboardImage ? "" : "none", textAlign: "right" }} onClick={() => this.setState({ tenantVariables: { ...tenantVariables, sideScoreboardImage: null } })}>
                                                ❌
                                            </div>
                                            <img src={sideScoreboardImage} width="160" height="auto" alt="" />
                                            <br />
                                            <label htmlFor="sideScoreboardImage">Side Scoreboard Image</label>
                                            <div className="form-group">
                                                <input style={{ display: 'none' }} id="sideScoreboardImage" name="sideScoreboardImage" type="file" ref={input => { this.sideScoreboardImage = input; }} onChange={this.handleImageChange} />
                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('sideScoreboardImage').click()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: this.state.showTabThree ? 'block' : 'none' }}>
                                    <div style={{ display: this.state.showTabOnegameTextToggle ? 'block' : 'none' }}>
                                        <div className="form-group">
                                            <label htmlFor="winningHeader">Winning Header</label>
                                            <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={this.state.tenantVariables.winningHeader} onChange={this.handleChange} placeholder="You Win!" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="winningMessage">Winning Message</label>
                                            <RichTextEditor id="winningMessage" name="winningMessage" toolbarConfig={toolbarConfig} value={winningMessage} onChange={(text) => this.handleRichTextChange("winningMessage", text)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="losingHeader">Winning But No Prize Header</label>
                                            <input id="losingHeader" name="losingHeader" type="text" className="form-control" value={this.state.tenantVariables.losingHeader} onChange={this.handleChange} placeholder="Bingo but no prizes left!" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="losingMessage">Winning But No Prize Message</label>
                                            <RichTextEditor id="losingMessage" name="losingMessage" toolbarConfig={toolbarConfig} value={losingMessage} onChange={(text) => this.handleRichTextChange("losingMessage", text)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkedBingoHeader">Checked Bingo Header</label>
                                            <input id="checkedBingoHeader" name="checkedBingoHeader" type="text" className="form-control" value={this.state.tenantVariables.checkedBingoHeader} onChange={this.handleChange} placeholder="Verifying Stats" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="checkedBingoMessage">Checked Bingo Message</label>
                                            <RichTextEditor id="checkedBingoMessage" name="checkedBingoMessage" toolbarConfig={toolbarConfig} value={checkedBingoMessage} onChange={(text) => this.handleRichTextChange("checkedBingoMessage", text)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="toLateToGetBoardHeader">Not In Time Header</label>
                                            <input id="toLateToGetBoardHeader" name="toLateToGetBoardHeader" type="text" className="form-control" value={this.state.tenantVariables.toLateToGetBoardHeader} onChange={this.handleChange} placeholder="Oh No!" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="toLateToGetBoardBody">Not In Time Message</label>
                                            <RichTextEditor placeholder="All boards have been given out, come back next time!" id="toLateToGetBoardBody" name="toLateToGetBoardBody" toolbarConfig={toolbarConfig} value={toLateToGetBoardBody} onChange={(text) => this.handleRichTextChange("toLateToGetBoardBody", text)} />
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showTabTwogameTextToggle ? 'block' : 'none' }}>
                                        <div className="form-group">
                                            <label htmlFor="pageTitle">Page Title:</label>
                                            <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Bingo" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="playingTextHeader">Holding Header Text:</label>
                                            <input id="playingTextHeader" name="playingTextHeader" type="text" className="form-control" value={this.state.tenantVariables.playingTextHeader} onChange={this.handleChange} placeholder="No Game Up" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="playingTextBody">Holding Body Text:</label>
                                            <input id="playingTextBody" name="playingTextBody" type="text" className="form-control" value={this.state.tenantVariables.playingTextBody} onChange={this.handleChange} placeholder="Come back later to play" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                    <PhotoshopPicker onChangeComplete={this.handleChangeComplete} color={this.state.colorToShow} onAccept={() => this.acceptPicker()} onCancel={() => this.dismissPicker()} />
                </Modal>
            </div>
        );
    }
}

export default SetUpBrandingVariables;
