import React, { Component } from 'react';
import { database } from '../../base';
import { ref, onValue, set } from 'firebase/database';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
const CryptoJS = require("crypto-js");

class SetUpApi extends Component {
    constructor(props) {
        super(props);
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`;
        this.state = {
            apiKey: '',
            loading: true,
            mainApiUrl: url,
            startTimeVariable: 1,
            apiVariables: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });

        const apiVariablesRef = ref(database, 'apiVariables');
        onValue(apiVariablesRef, (snapshot) => {
            const apiVariables = snapshot.val() || {};
            this.setState({
                apiVariables,
                loading: false
            });
        }, (error) => {
            console.log(error);
            this.setState({ loading: false });
        });

        const idKeyRef = ref(database, 'id_key');
        onValue(idKeyRef, (snapshot) => {
            const id_key = snapshot.val();
            this.setState({ id_key });
        });
    }

    handleChange(evt) {
        const apiVariablesCopy = { ...this.state.apiVariables };
        const target = evt.target;
        const name = target.name;
        apiVariablesCopy[name] = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ apiVariables: apiVariablesCopy });
    }

    handleSubmit(event) {
        event.preventDefault();
        const apiVariablesRef = ref(database, 'apiVariables');
        set(apiVariablesRef, this.state.apiVariables);
    }

    generateKey(size = 32) {
        const buffer = CryptoJS.lib.WordArray.random(size);
        const apiVariablesCopy = { ...this.state.apiVariables };
        apiVariablesCopy.currentApiKey = buffer.toString(CryptoJS.enc.Hex);
        if (!apiVariablesCopy.gameName) {
            apiVariablesCopy.gameName = process.env.REACT_APP_API_GAME_NAME;
        }
        this.setState({ apiVariables: apiVariablesCopy }, () => {
            const apiVariablesRef = ref(database, 'apiVariables');
            set(apiVariablesRef, this.state.apiVariables);
        });
    }

    deleteKey() {
        this.setState({ apiVariables: null }, () => {
            const apiVariablesRef = ref(database, 'apiVariables');
            set(apiVariablesRef, null);
        });
    }

    render() {
        const apiVariables = this.state.apiVariables || {};
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "sqwadbingodev";
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Api Management</p>
                    <p className="admin-subheader-text">Manage your api key here that you can use to access data programmatically</p>
                    {apiVariables.currentApiKey &&
                        <div style={{ marginLeft: 20, color: "black" }}>
                            Current Key: <br />{apiVariables.currentApiKey}
                            <button onClick={() => this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginLeft: 20 }}>
                                <span className="fa fa-refresh" /> Generate New Key
                            </button>
                            <button onClick={() => this.deleteKey()} className="btn btn-primary btn-lg delete-button" id="submitButton" style={{ marginLeft: 20 }}>
                                <span className="fa fa-trash" /> Delete
                            </button>
                        </div>
                    }
                    {!apiVariables.currentApiKey &&
                        <div>
                            <button onClick={() => this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: '20px', marginLeft: 20 }}>
                                <span className="fa fa-refresh" /> Generate Key
                            </button>
                        </div>
                    }
                    <div style={{ display: "none" }}>
                        <div className="form-group">
                            <label htmlFor="gameName">Game Name:</label>
                            <input id="gameName" name="gameName" type="text" className="form-control" value={apiVariables.gameName} onChange={this.handleChange} placeholder={process.env.REACT_APP_API_GAME_NAME} />
                        </div>
                    </div>
                    {isDev &&
                        <div className="form-group">
                            <label htmlFor="startTimeVariable">startTime:</label>
                            <input id="startTimeVariable" name="startTimeVariable" type="number" className="form-control" value={this.state.startTimeVariable} onChange={(startTimeVariable) => this.setState({ startTimeVariable: startTimeVariable.target.value })} placeholder="1" />
                        </div>
                    }
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Documentation</p>
                    <p className="admin-subheader-text">How to use the games API</p>
                    <a href="/apidocumentation" style={{ marginLeft: 20 }}>See the docs</a>
                </div>
            </div>
        );
    }
}

export default SetUpApi;
