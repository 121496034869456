import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { ref, onValue, update, remove } from 'firebase/database';
import { database } from '../../base';
import '../../styles/css/AdminMain.css';
import { ErrorMessage, Toast } from '../utils/HelpfulFunction';
import RichTextEditor from 'html-verify-react-rte';

class SetUpEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            emailVariables: '',
            loading: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentWillUnmount() {
        if (this.emailVariablesRef) {
            remove(this.emailVariablesRef);
        }
    }

    componentDidMount() {
        const emailVariablesRef = ref(database, 'emailVariables');
        onValue(emailVariablesRef, (snapshot) => {
            const data = snapshot.val() || {};
            let advanced = false;
            if (data && data.sender) {
                advanced = true;
            }
            this.setState({
                loading: false,
                advanced: advanced,
                emailVariables: data
            });
        }, (error) => {
            this.setState({
                loading: false
            });
        });
        this.emailVariablesRef = emailVariablesRef;
    }

    handleChange(event) {
        let target = event.target;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked
            this.setState({ [target.name]: value });
        } else {
            let emailVariablesCopy = this.state.emailVariables || {};
            emailVariablesCopy[target.name] = value;
            this.setState({ emailVariables: emailVariablesCopy });
        }
    }

    handleImageChange(event) {
        const name_of_file = event.target.name;
        const target = this[name_of_file];
        const file_to_update = target.files[0];
        this.setState({ loading: true });
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({ loading: false });
            if (res.error) {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: res.error
                });
            } else if (res.imageUrl) {
                let emailVariablesCopy = vm.state.emailVariables;
                emailVariablesCopy[name_of_file] = res.imageUrl;
                vm.setState({
                    emailVariables: emailVariablesCopy,
                }, () => {
                    vm[name_of_file].value = "";
                });
            } else {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: "Something went wrong, please re-upload your image and try again!"
                });
            }
        });
    }

    onChangeHelpText = (helpText) => {
        let emailVariablesCopy = this.state.emailVariables || {};
        emailVariablesCopy["helpText"] = helpText;
        this.setState({ emailVariables: emailVariablesCopy });
    }

    handleSubmit(event) {
        event.preventDefault();
        const emailVariables = this.state.emailVariables;
        let sender = emailVariables.sender || "";
        let subjectLine = emailVariables.subjectLine || "";
        let preview = emailVariables.preview || "";
        let helpText = emailVariables.helpText || "";
        let backgroundImage = emailVariables.emailBackgroundImage || "";
        if (helpText === "<p><br></p>") {
            helpText = "";
        } else {
            helpText = helpText.toString('html');
        }
        this.setState({ loading: true });
        const updateRulesObject = {
            helpText,
            subjectLine,
            emailBackgroundImage: backgroundImage,
            preview,
            sender
        };
        const vm = this;
        update(ref(database, 'emailVariables'), updateRulesObject).then(() => {
            vm.setState({ loading: false });
            Toast.fire({
                title: 'Email Variables Updated!'
            });
        }).catch(err => {
            vm.setState({ loading: false });
            ErrorMessage.fire({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in'
            });
        });
    }

    render() {
        let sender = this.state.emailVariables.sender || "";
        let subjectLine = this.state.emailVariables.subjectLine || "";
        let preview = this.state.emailVariables.preview || "";
        let helpText = this.state.emailVariables.helpText || "";
        if (typeof helpText === 'string') {
            helpText = RichTextEditor.createValueFromString(helpText, 'html');
        } else if (!helpText) {
            helpText = RichTextEditor.createEmptyValue();
        }
        let emailBackgroundImage = this.state.emailVariables.emailBackgroundImage;
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>{isMlbApp ? "Message Branding" : "Email Branding"}</p>
                    <p className="admin-subheader-text">This is where you can edit the {isMlbApp ? "message" : "email"} sent to fans</p>
                    <div className="container-out">
                        <div className="admin-form-box">
                            <form onSubmit={this.handleSubmit} id="create-email-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: 20 }}><span className="fa fa-arrow-circle-o-up" /> Update</button>
                                <div className="row col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="subjectLine">{isMlbApp ? "Message" : "Email"} Subject Line</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is the subject line that your fans will see when they receive their winning {isMlbApp ? "messages" : "emails"}</p>
                                        <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                                    </div>
                                </div>
                                {!isMlbApp &&
                                    <>
                                        <div className="row col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="preview">Email Preview Text</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is the preview text for the email, if left blank it will be replaced by the subject line. For mobile optimization the length should be between 35-50 characters but for desktop the preview can be up to 140 characters and beyond</p>
                                                <input id="preview" name="preview" className="form-control" value={preview} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-3">
                                                <label htmlFor="helpText">Help Text</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is where you can tell fans where to contact you with issues. (Example: Having issues? Email info@spintowin.com for help.)</p>
                                                <RichTextEditor toolbarConfig={toolbarConfig} style={{ minHeight: '100px' }} id="helpText" name="helpText" value={helpText} onChange={this.onChangeHelpText} />
                                            </div>
                                            <div className="form-group col-md-9" align="center">
                                                <img src={emailBackgroundImage} width="auto" height="100px" alt="" />
                                                <br />
                                                <label htmlFor="backgroundImage">Email Header Image<br />(700px X 400px)</label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => { this.emailBackgroundImage = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {!isMlbApp &&
                                    <div className="form-group">
                                        <label htmlFor="showAdvancedSettings">Advanced</label>
                                        <br />
                                        <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange} />
                                    </div>
                                }
                                {this.state.advanced &&
                                    <div className="form-group">
                                        <label htmlFor="sender">Email Sender</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is the sender your fans will see when they receive their winning emails. BE AWARE: changing the sender could adversely impact delivery rates</p>
                                        <input id="sender" name="sender" type="email" className="form-control" value={sender} onChange={this.handleChange} />
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpEmail;
